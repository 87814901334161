import React, { useState } from 'react';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';

export default function RangeSlider({ setFilter, filter }) {
  
  const [value, setValue] = useState([filter.minscore, filter.maxscore]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeCommitted = (event, newValue) => {
    setFilter(previousState => ({
      ...previousState,
      minscore: newValue[0],
      maxscore: newValue[1]
    }));
    console.log(filter, 'newvalue');
  };

  const valuetext = (value) => {
    return `${value}`;
  };

  return (
    <Box sx={{ width: '18vh' }}>
      <div className='score-slider'>Score</div>
      <Slider
        getAriaLabel={() => 'Score'}
        value={value}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommitted}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        min={0.0}
        max={10.0}
        step={0.1}
        size="small"
      />
    </Box>
  );
}