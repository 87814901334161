import MapBoxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { useControl } from "react-map-gl";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

const Geocoder = ({ department,proximity1 }) => {
  
  const ctrl = new MapBoxGeocoder({
    accessToken:
      "pk.eyJ1IjoiY2hhaDYwNDg4IiwiYSI6ImNsc2E3ZmR2bjAwOHUybG81dzE0c24ybnYifQ.APsYLkryQsu19baQz4y0bg",
    marker: false,
    collapsed: true,
    countries: "FR", // Prioriser la recherche en France uniquement
    
    proximity: proximity1
    ? proximity1
    : undefined,

    trackProximity : false,

  });

  useControl(() => ctrl);
  ctrl.on("result", (e) => {
    const proximity = ctrl.options.proximity; // Accessing bbox parameter
    //console.log("proximity",proximity)
    // console.log("department",department)
    //console.log("Bounding box:", bbox); // Logging bbox
  });
  return null;
};

export default Geocoder;
