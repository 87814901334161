import React, { useState, useEffect } from "react";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
  Grid,
  FormLabel,
} from "@mui/material";
import axios from "axios";
import { formatISO, parseISO, addHours } from "date-fns";
import Swal from "sweetalert2";

const Formulaire = (props) => {
  const initialState = {
    dateOption: "",
    eventType: "",
    reportSubtype: "",
    street: "",
    direction: "",
    starttime: "",
    endtime: "",
    description: "Intervention gendarmerie nationale",
    duration: 1,
  };

  const [formData, setFormData] = useState(initialState);

  useEffect(() => {
    if (props.marker) {
      fetchStreetNameFromPolyline(props.marker);
    }
  }, [props.marker]);

  useEffect(() => {
    if (formData.endtime) {
      // Any specific logic when endtime changes
    }
  }, [formData.endtime]);

  const handleStarttimeChange = (event) => {
    const newStarttime = event.target.value;
    setFormData((prevState) => ({ ...prevState, starttime: newStarttime }));
    updateEndtime(newStarttime, formData.duration);
  };

  const handleDurationChange = (event) => {
    const durationValue = parseInt(event.target.value, 10);
    setFormData((prevState) => ({ ...prevState, duration: durationValue }));
    //console.log(durationValue, "durationValue");
    updateEndtime(formData.starttime, durationValue);
  };

  const handleStreetChange = (event) => {
    setFormData((prevState) => ({ ...prevState, street: event.target.value }));
  };

  const handleDateOptionChange = (event) => {
    setFormData((prevState) => ({
      ...prevState,
      dateOption: event.target.value,
    }));
    const now = formatISO(new Date(), { representation: "dateTime" }).slice(
      0,
      16
    );
    setFormData((prevState) => ({ ...prevState, starttime: now }));
    updateEndtime(now, formData.duration);
  };

  const updateEndtime = (start, duration) => {
    if (isNaN(duration) || duration <= 0 || !start) {
      setFormData((prevState) => ({ ...prevState, endtime: "" }));
      return;
    }
    const startTime = parseISO(start);
    const endTime = addHours(startTime, duration);
    const endTimeISO = formatISO(endTime, { representation: "dateTime" }).slice(
      0,
      16
    );
    setFormData((prevState) => ({ ...prevState, endtime: endTimeISO }));
    //console.log(startTime, "startTime in function update");
    //console.log(endTime, "endTime in function update");
  };

  const fetchStreetNameFromPolyline = async (polyline) => {
    if (typeof polyline !== "string") {
      console.error("Invalid polyline data");
      return;
    }

    const coordinates = polyline.split(" ");
    if (coordinates.length < 2) {
      console.error("Polyline data is not sufficient");
      return;
    }

    // Prepare lists of latitudes and longitudes
    const latitudes = [];
    const longitudes = [];
    for (let i = 0; i < coordinates.length; i += 2) {
      longitudes.push(coordinates[i]);
      latitudes.push(coordinates[i + 1]);
    }

    const url = `${
      process.env.REACT_APP_BACK_URL
    }/geocoder?lat=${latitudes.join("&lat=")}&long=${longitudes.join(
      "&long="
    )}`;
    const config = {
      headers: {
        Authorization: "Bearer " + props.token,
      },
    };

    try {
      const response = await axios.get(url, config);
      //console.log(response.data, 'RESPONSE'); // Log the entire response data

      if (response.data.length > 0) {
        const streetName = response.data; // Assume the backend returns the most frequent street name
        setFormData((prevState) => ({ ...prevState, street: streetName }));
      } else {
        console.error("No street names found in the response");
      }
    } catch (error) {
      console.error("Error fetching street name:", error);
    }
  };

  const postData = (event) => {
    event.preventDefault();

    if (
      !formData.direction ||
      !formData.street ||
      !formData.starttime ||
      !formData.endtime
    ) {
      console.log(formData,'FORMDATA')
      alert("Veuillez remplir tous les champs.");
      return;
    }

    // Convert starttime and endtime to milliseconds
    const startTimeMillis = new Date(formData.starttime).getTime();
    const endTimeMillis = new Date(formData.endtime).getTime();
    const currentTime = Date.now(); // Obtient l'horodatage actuel
    // console.log(currentTime, 'debog');

    // console.log('Form Data:', {
    //   description: formData.description,
    //   street: formData.street,
    //   direction: formData.direction,
    //   polyline: `${props.marker}`,
    //   type: formData.eventType,
    //   subtype: formData.reportSubtype,
    //   starttime: startTimeMillis,
    //   endtime: endTimeMillis,
    //   creationtime: currentTime,
    //   updatetime: currentTime,
    // });

    axios({
      method: "POST",
      headers: {
        Authorization: "Bearer " + props.token,
      },
      url:
        formData.eventType === "ROAD_CLOSED"
          ? `${process.env.REACT_APP_BACK_URL}/add_data`
          : `${process.env.REACT_APP_BACK_URL}/add_data?type=incident`,

      data: JSON.stringify({
        description: formData.description,
        street: formData.street,
        direction: formData.direction,
        polyline: `${props.marker}`,
        type: formData.eventType,
        subtype: formData.reportSubtype,
        starttime: startTimeMillis,
        endtime: endTimeMillis,
        creationtime: currentTime,
        updatetime: currentTime,
        department: props.department,
      }),
    })
      .then((response) => {
        //console.log(response);
        Swal.fire({
          title: "La route a bien été fermée.",
          icon: "success",
          showCancelButton: true,
          confirmButtonText: "Retour à la carte",
          cancelButtonText: "Fermer une autre route",
          customClass: {
            confirmButton: "button",
            cancelButton: "button",
            title: "custom-title-class",
          },
        }).then((result) => {
          setFormData(initialState);
          if (result.dismiss === Swal.DismissReason.confirm) {
            window.location.href = "/visu_grande_carte";
          }
          if (result.dismiss === Swal.DismissReason.cancel) {
            props.setnClickUpdate(props.nClickUpdate + 1);
          }
        });
      })
      .catch((error) => {
        if (error.response) {
          console.log(props.token);
          console.log(error.response);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  };

  const Signaler_intervention = (event) => {
    event.preventDefault();

    if (
      !formData.street ||
      !formData.starttime ||
      !formData.endtime
    ) {
      console.log(formData,'FORMDATA')
      alert("Veuillez remplir tous les champs.");
      return;
    }

    // Convert starttime and endtime to milliseconds
    const startTimeMillis = new Date(formData.starttime).getTime();
    const endTimeMillis = new Date(formData.endtime).getTime();
    const currentTime = Date.now(); // Obtient l'horodatage actuel
    // console.log(currentTime, 'debog');

    // console.log('Form Data:', {
    //   description: formData.description,
    //   street: formData.street,
    //   direction: formData.direction,
    //   polyline: `${props.marker}`,
    //   type: formData.eventType,
    //   subtype: formData.reportSubtype,
    //   starttime: startTimeMillis,
    //   endtime: endTimeMillis,
    //   creationtime: currentTime,
    //   updatetime: currentTime,
    // });

    axios({
      method: "POST",
      headers: {
        Authorization: "Bearer " + props.token,
      },
      url:
        formData.eventType === "ROAD_CLOSED"
          ? `${process.env.REACT_APP_BACK_URL}/add_data`
          : `${process.env.REACT_APP_BACK_URL}/add_data?type=incident`,

      data: JSON.stringify({
        description: formData.description,
        street: formData.street,
        direction: "ONE_DIRECTION",
        polyline: `${props.marker}`,
        type: formData.eventType,
        subtype: formData.reportSubtype,
        starttime: startTimeMillis,
        endtime: endTimeMillis,
        creationtime: currentTime,
        updatetime: currentTime,
        department: props.department,
      }),
    })
      .then((response) => {
        //console.log(response);
        Swal.fire({
          title: "La route a bien été fermée.",
          icon: "success",
          showCancelButton: true,
          confirmButtonText: "Retour à la carte",
          cancelButtonText: "Fermer une autre route",
          customClass: {
            confirmButton: "button",
            cancelButton: "button",
            title: "custom-title-class",
          },
        }).then((result) => {
          setFormData(initialState);
          if (result.dismiss === Swal.DismissReason.confirm) {
            window.location.href = "/visu_grande_carte";
          }
          if (result.dismiss === Swal.DismissReason.cancel) {
            props.setnClickUpdate(props.nClickUpdate + 1);
          }
        });
      })
      .catch((error) => {
        if (error.response) {
          console.log(props.token);
          console.log(error.response);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  };

  return (
    <div className="formulaire">
      <form id="addDataForm" name="addDataForm">
      <Grid item xs={12}>
  <FormControl component="fieldset">
    <FormLabel component="legend">
      Quel type d'action souhaitez vous effectuer ?
    </FormLabel>
    <RadioGroup
      row
      aria-label="action"
      name="action"
      id="action"
      value={formData.eventType}
      onChange={(event) => {
        const newEventType = event.target.value;
        const newReportSubtype = event.target.getAttribute("data-value2");
        setFormData((prevState) => ({
          ...prevState,
          eventType: newEventType,
          reportSubtype: newReportSubtype,
          ...(newReportSubtype === "HAZARD_ON_ROAD" && { direction: "BOTH_DIRECTION" }),
        }));
      }}
    >
      <FormControlLabel
        value="ROAD_CLOSED"
        control={<Radio data-value2="ROAD_CLOSED_HAZARD" />}
        label="Fermer une route sur waze"
      />
      <FormControlLabel
        value="HAZARD"
        control={<Radio data-value2="HAZARD_ON_ROAD" />}
        label="Signaler une intervention de la gendarmerie sans fermer de route"
      />
    </RadioGroup>
  </FormControl>
</Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            {formData.eventType === "ROAD_CLOSED" ? (
              <div>
                Pour signaler une route dont la fermeture est prévue, veuillez
                remplir le formulaire.
              </div>
            ) : (
              <div>
                Pour signaler effectuer un signalement, veuillez remplir le
                formulaire.
              </div>
            )}
          </Grid>

          {formData.eventType === "ROAD_CLOSED" && (
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Direction</FormLabel>
                <RadioGroup
                  row
                  aria-label="direction"
                  name="direction"
                  id="direction"
                  value={formData.direction}
                  onChange={(event) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      direction: event.target.value,
                    }));
                  }}
                >
                  <FormControlLabel
                    value="ONE_DIRECTION"
                    control={<Radio />}
                    label="Sens unique"
                  />
                  <FormControlLabel
                    value="BOTH_DIRECTION"
                    control={<Radio />}
                    label="Double sens"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          )}

          <Grid item xs={12}>
            <FormLabel component="legend">Début</FormLabel>
            <RadioGroup
              row
              aria-label="date"
              name="date"
              id="date"
              value={formData.dateOption}
              onChange={handleDateOptionChange}
            >
              <FormControlLabel
                value="now"
                control={<Radio />}
                label="Maintenant"
              />
              <FormControlLabel
                value="program"
                control={<Radio />}
                label="Programmer"
              />
            </RadioGroup>

            {formData.dateOption === "program" && (
              <TextField
                id="starttime"
                label="début blocage"
                type="datetime-local"
                value={formData.starttime}
                onChange={handleStarttimeChange}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: "2024-01-01T00:00",
                  max: "2024-12-31T23:59",
                }}
              />
            )}
          </Grid>

          <Grid item xs={12}>
            <FormControl style={{ width: "20vh" }}>
              <InputLabel id="durationlabel">Durée (heures)</InputLabel>
              <Select
                labelId="durationlabel"
                id="duration"
                name="duration"
                label="Durée (heures)"
                value={formData.duration}
                onChange={handleDurationChange}
                required
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <div className="consigne-formulaire">
              Placer des marqueurs sur la route concernée en double cliquant sur
              la carte. <br /> La polyline et la route se rempliront
              automatiquement.
            </div>
            <TextField
              id="polyline"
              name="polyline"
              label="Polyline, attention la longueur d'une route fermée ne doit pas dépasser les 20km"
              value={`${props.marker}`}
              required
              fullWidth
              disabled
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="street"
              name="street"
              label="Route : ce champ se remplira automatiquemment"
              value={formData.street}
              onChange={handleStreetChange}
              required
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
          {formData.eventType === "ROAD_CLOSED" ? (
            <Button
              type="submit"
              variant="contained"
              className="button"
              onClick={postData}
            >
              Fermer la route
            </Button>) :(<Button
              type="submit"
              variant="contained"
              className="button"
              onClick={Signaler_intervention}
            >
              Signaler une intervention
            </Button>) }
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default Formulaire;
