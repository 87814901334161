import React, { useRef } from "react";
import { FlyToInterpolator } from 'react-map-gl';
import Data_grande_carte from "./components/Data_grande_carte";
import Grid from "@mui/material/Grid";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import traductionJson from "./traduction.json";
import BarLoader from "react-spinners/BarLoader";
import { Slide } from "@mui/material";
import Grande_carte from "./components/grande_carte";
import fermer_route from "./img/fermer_route.png";
import hamburger from "./img/hamburger.png";

import danger_img from "./img/danger_route.png";
import route_barree from "./img/route_barree.png";
import accident_img from "./img/accident_route.png";
import embouteillage from "./img/embouteillage.png";
import neige from "./img/neige.png";
import obstacle from "./img/obstacle.png";
import feu from "./img/feu.png";
import voiture_arretee from "./img/voiture_arretee.png";
import chantier from "./img/chantier.png";
import meteo from "./img/meteo.png";

function Visu_grande_carte(props) {
  
  const imageMap = {
    reporttype: {
      Danger: danger_img,
      Accident: accident_img,
      "Route fermée": route_barree,
      Embouteillage: embouteillage,
    },
    reportsubtype: {
      "Vague de chaleur": feu,
      "Travaux - route": chantier,
      "Route fermée pour travaux": chantier,
      HAZARD_WEATHER: meteo,
      Brouillard: meteo,
      "Pluie forte": meteo,
      Inondation: meteo,
      Mousson: meteo,
      Tornade: meteo,
      Ouragan: meteo,
      "Pluie verglaçante": meteo,
      "Neige abondante": neige,
      Grêle: neige,
      "Objet - route": obstacle,
      "Véhicule arrêté bas-côté": voiture_arretee,
      "Véhicule arrêté - route": voiture_arretee,
    },
  };

  const mapRef = useRef();
  const [sumlength, setSumlength] = useState(null);
  const [historicsumlength, sethistoricSumlength] = useState(null);
  const containerRef = useRef(null);
  const [sortbydate, setSortbydate] = useState(true);
  const [showData, setShowData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [Firstrun, setFirstrun] = useState(null);
  const navigate = useNavigate();
  const [department, setDepartment] = useState("");
  const [clickdata, setclickdata] = useState('a');
  const [alerts, setalerts] = useState();
  const [tipi, settipi] = useState();
  const [jamsdata, setjamsdata] = useState();
  const [irregularity, setirregularity] = useState();
  const departmentJsonFeatures = [];

  const brigadesgeoJsonFeatures = [];
  const [brigadesgeoJson, setbrigadesGeoJson] = useState(null);
  const [departmentgeoJson, setdepartmentGeoJson] = useState(null);
  const [geoJson, setGeoJson] = useState(null);
  const [geoJson_filtered, setGeoJson_filtered] = useState(null);
  const [expanded, setExpanded] = useState(true);
  const [tipigeoJson, settipiGeoJson] = useState(null);
  const [tipigeoJson_filtered, settipiGeoJson_filtered] = useState(null);
  const [jamgeoJson, setJamGeoJson] = useState(null);
  const [irregularitygeoJson, setIrregularityGeoJson] = useState(null);
  const [irregularitygeoJson_filtered, setIrregularityGeoJson_filtered] =
    useState(null);
  const [jamgeoJson_filtered, setjamgeoJson_filtered] =
    useState(null);
  
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [selectedLine, setSelectedLine] = useState(null);
  const [anomalies, setanomalies] = useState(null);
  const [analyse, setanalyse] = useState(null);
  const [anomalies2, setanomalies2] = useState(null);
  const [viewport, setViewport] = useState({
    latitude: 43.698346,
    longitude: 5.005279,
    zoom: 8,
    width: "100%",
    height: "100vh",
  });

  const [initialviewport, setInitialviewport] = useState({
    latitude: 43.698346,
    longitude: 5.005279,
    zoom: 8,
    width: "100%",
    height: "100vh",
  });


  const today = new Date().getDay();

  const Updatedata = (newdata) => {
    setclickdata(newdata);
  };

  const [mapcoords, setMapcoords] = useState([6, 44]);

  
  const Updatemapcoords = (newmapcoords) => {
  if (typeof newmapcoords === "string") {
    const [longitude, latitude] = newmapcoords
      .slice(1, -1) // Remove parentheses
      .split(",") // Split at comma
      .map(parseFloat); // Convert strings to floats
    setMapcoords([latitude, longitude]);
  } else if (newmapcoords === -1) {
    mapRef.current.flyTo({zoom: 10});
  } else if (Array.isArray(newmapcoords)) {
    setMapcoords(newmapcoords);
  } else {
    console.error("Invalid map coordinates format.");
  }
};

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACK_URL}/profile`,
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + props.token,
            },
          }
        );

        if (!response.ok) {
          if (response.status === 401 || response.status === 422) {
            navigate("/login"); // Use navigate to redirect to "/login"
          } else {
            // Handle other error responses
            console.error("Error fetching profile data:", response.statusText);
          }
          return;
        }

        //définir le département de l'utilisateur
        const data = await response.clone().json();

        setDepartment(data.department);
        props.setFilter(data.filter)
        props.setParametres_json(data.parametres_json)


        return await response.clone().json();
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    if (department) {
      // Define a function to fetch data and manage loading state
      const fetchData = async () => {
        try {
          // Fetch data from APIs
          const data = await fetchalerts();
          const jamdata = await fetchjamsdata();
          const irregularityData = await fetchirregularity();
          const anomaliesData = await fetchanomalies();
          const data_analyse = await fetchDataanalyse();

          const tipi = await fetchtipi();

          // Update states or props with fetched data
        
          setanomalies(anomaliesData);
    

          const currentDate = new Date();
          const Hours = currentDate.getHours();
          const Min = currentDate.getMinutes().toString().padStart(2, "0");
          props.setMaj(Hours + "H" + Min);
        } catch (error) {
          // Handle any errors if needed
          console.error("Error fetching data:", error);
        }
      };

      // Fetch data immediately when component mounts
      fetchData();

      // Set interval to fetch data every 3 minutes
      const intervalId = setInterval(fetchData, 3 * 60 * 1000); // 3 minutes

      // Clear interval when component unmounts or when department changes
      return () => clearInterval(intervalId);
    }
  }, [department]);

  useEffect(() => {
    if (Firstrun === null) {
      setLoading(true); // Set loading to true when fetching starts
    }
    if (anomalies !== null) {
      const newValue = true;
      setFirstrun(newValue);
      setLoading(false);
    }
  }, [anomalies]);

  useEffect(() => {
    if (department) {
      fetchdepartment();
      fetchbrigades();
      fetchalertsONCE();
    }
    
  }, [department]);

  
  useEffect(() => {
    if (mapcoords && mapRef.current != null) {
      
      mapRef.current.flyTo({
        center: [mapcoords[1], mapcoords[0]], // Note that it's [longitude, latitude]
        zoom: 16
      });
    }
  }, [mapcoords]);

  useEffect(() => {
    if (geoJson && geoJson.features) {
      const geojsonFilter = geoJson.features.map((item) => {
        const score = item.properties.score;
        const scoreInRange = score >= props.filter.minscore && score <= props.filter.maxscore;
        let isinfeed = true; // Initialisation à true par défaut
        // Si le type de rapport n'est pas dans le tableau type du filtre, isinfeed est faux
        if (!props.filter.type.includes(item.properties.category)) {
          isinfeed = false;
        }
        if (
          props.filter.displayPolice === false &&
          item.properties.isbrigade === false
        ) {
          isinfeed = false;
        }
        // Mettre à jour la propriété isinfeed
        return {
          ...item,
          properties: {
            ...item.properties,
            isinfeed: isinfeed && scoreInRange, // Mettre à jour isinfeed en fonction du scoreInRange
          },
        };
      });
      setGeoJson_filtered({ ...geoJson, features: geojsonFilter });
    } else {
      // console.error("geoJson.features n'est pas défini");
    }
  }, [props.filter, geoJson]);

  useEffect(() => {
    if (irregularitygeoJson && irregularitygeoJson.features) {
      const geojsonFilter = irregularitygeoJson.features.map((item) => {
        const score = item.properties.score;
        const scoreInRange =
          score >= props.filter.minscore && score <= props.filter.maxscore;
        let isinfeed = true; // Initialisation à true par défaut

        // Si le type de rapport n'est pas dans le tableau type du filtre, isinfeed est faux
        if (!props.filter.type.includes("embouteillage")) {
          isinfeed = false;
        }

        // Mettre à jour la propriété isinfeed
        return {
          ...item,
          properties: {
            ...item.properties,
            isinfeed: isinfeed && scoreInRange, // Mettre à jour isinfeed en fonction du scoreInRange
          },
        };
      });

      setIrregularityGeoJson_filtered({
        ...irregularitygeoJson,
        features: geojsonFilter,
      });
    } else {
      // console.error("irregularitygeoJson.features n'est pas défini");
    }
  }, [props.filter, irregularitygeoJson]);

  useEffect(() => {
    if (jamgeoJson && jamgeoJson.features) {
      const geojsonFilter = jamgeoJson.features.map((item) => {
        const score = item.properties.score;
        const scoreInRange =
          score >= props.filter.minscore && score <= props.filter.maxscore;
        let isinfeed = true; // Initialisation à true par défaut

        // Si le type de rapport n'est pas dans le tableau type du filtre, isinfeed est faux
        if (!props.filter.type.includes("embouteillage")) {
          isinfeed = false;
        }

        // Mettre à jour la propriété isinfeed
        return {
          ...item,
          properties: {
            ...item.properties,
            isinfeed: isinfeed && scoreInRange, // Mettre à jour isinfeed en fonction du scoreInRange
          },
        };
      });

      setjamgeoJson_filtered({
        ...jamgeoJson,
        features: geojsonFilter,
      });
    } else {
      // console.error("jamgeoJson.features n'est pas défini");
    }
  }, [props.filter, jamgeoJson]);

  useEffect(() => {
    if (tipigeoJson && tipigeoJson.features) {
      const geojsonFilter = tipigeoJson.features.map((item) => {
        const score = item.properties.score;
        const scoreInRange =
          score >= props.filter.minscore && score <= props.filter.maxscore;
        let isinfeed = true; // Initialisation à true par défaut

        // Si le type de rapport n'est pas dans le tableau type du filtre, isinfeed est faux
        if (!props.filter.type.includes("accident")) {
          isinfeed = false;
        }

        // Mettre à jour la propriété isinfeed
        return {
          ...item,
          properties: {
            ...item.properties,
            isinfeed: isinfeed && scoreInRange, // Mettre à jour isinfeed en fonction du scoreInRange
          },
        };
      });

      settipiGeoJson_filtered({ ...tipigeoJson, features: geojsonFilter });
    } else {
      // console.error("geoJson.features n'est pas défini");
    }
  }, [props.filter, tipigeoJson]);

  function traduction(type) {
    if (traductionJson.hasOwnProperty(type)) {
      return traductionJson[type];
    } else {
      return type; // Si pas de traduction ne traduit pas
    }
  }

  function SaveFilter() {
    axios({
      method: "POST",
      headers: {
        Authorization: "Bearer " + props.token,
      },
      url: `${process.env.REACT_APP_BACK_URL}/save-filter`,
      data: JSON.stringify({
        filter: props.filter,
        username : props.username,
        parametres_json : props.parametres_json,
      })
    })
      // .then((response) => {
      //   props.token();
      //   navigate("/login");
      //   props.setDepartment(null);
      // })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  }

  const fetchalerts = async () => {
    return axios
      .get(
        `${process.env.REACT_APP_BACK_URL}/located-data?department=${department}`
        ,{
          headers: {
            Authorization: "Bearer " + props.token,
          },
        }
      )
      .then(({ data }) => {
        const translatedData = data.map((item) => ({
          ...item,
          reportsubtype: traduction(item.reportsubtype),
          reporttype: traduction(item.reporttype),
        }));
        // Set the translated data
        setalerts(translatedData);
        const geoJsonFeatures = [];
        data.forEach = data.map((item, index) => {
          const [longitude, latitude] = item.location
            .slice(1, -1) // Remove parentheses
            .split(",") // Split at comma
            .map(parseFloat); // Convert strings to floats
          //Conversion de data en geojson pour pouvoir modifier la carte mapbox
          const features = {
            type: "Feature",
            id: index.toString(),
            geometry: {
              type: "Point",
              coordinates: [longitude, latitude],
            },
            properties: {
              // id: index.toString(),
              confidence: item.confidence,
              country: item.country,
              magvar: item.magvar,
              pubMillis: item.pubmillis,
              reliability: item.reliability,
              reportDescription: item.reportDescription,
              reportbymunicipalityuser: item.reportbymunicipalityuser,
              reportrating: item.reportrating,
              reportsubtype: traduction(item.reportsubtype),
              reporttype: traduction(item.reporttype),
              roadtype: item.roadtype,
              uuid: item.uuid,
              isirregularity: item.isirregularity,
              isbrigade: item.isbrigade,
              score: item.score,
              isinfeed: true,
              category : item.category,
              pubmillis_unix: convertToUnix(item.pubmillis)
            },
          };

          geoJsonFeatures.push(features);
        });

        const geoJson = {
          type: "FeatureCollection",
          features: geoJsonFeatures,
        };

        setGeoJson(geoJson);

        return JSON.stringify(data, null, 2);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchalertsONCE = async () => {
    return axios
      .get(
        `${process.env.REACT_APP_BACK_URL}/located-data?department=${department}`
        ,{
          headers: {
            Authorization: "Bearer " + props.token,
          },
        }
      )
      .then(({ data }) => {
        const translatedData = data.map((item) => ({
          ...item,
          reportsubtype: traduction(item.reportsubtype),
          reporttype: traduction(item.reporttype),
        }));
        // Set the translated data
        // setalerts(translatedData);
        const geoJsonFeatures = [];
        data.forEach = data.map((item, index) => {
          const [longitude, latitude] = item.location
            .slice(1, -1) // Remove parentheses
            .split(",") // Split at comma
            .map(parseFloat); // Convert strings to floats
          //Conversion de data en geojson pour pouvoir modifier la carte mapbox
          const features = {
            type: "Feature",
            id: index.toString(),
            geometry: {
              type: "Point",
              coordinates: [longitude, latitude],
            },
            properties: {
              // id: index.toString(),
              confidence: item.confidence,
              country: item.country,
              magvar: item.magvar,
              pubMillis: item.pubmillis,
              reliability: item.reliability,
              reportDescription: item.reportDescription,
              reportbymunicipalityuser: item.reportbymunicipalityuser,
              reportrating: item.reportrating,
              reportsubtype: traduction(item.reportsubtype),
              reporttype: traduction(item.reporttype),
              roadtype: item.roadtype,
              uuid: item.uuid,
              isirregularity: item.isirregularity,
              isbrigade: item.isbrigade,
              score: item.score,
              isinfeed: true,
              category : item.category,
            },
          };

          geoJsonFeatures.push(features);
        });

        const geoJson = {
          type: "FeatureCollection",
          features: geoJsonFeatures,
        };

        // setGeoJson(geoJson);

        const firstFeatureCoordinates =
            geoJson.features[0].geometry.coordinates;
          


        return JSON.stringify(data, null, 2);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchjamsdata = async () => {
    return axios
      .get(
        `${process.env.REACT_APP_BACK_URL}/located-jam-data?department=${department}`,{headers: {
          Authorization: "Bearer " + props.token,
        },}
      )
      .then(({ data }) => {
        setjamsdata(data);
        const jamgeoJsonFeatures = [];
        data.forEach = data.map((item, index) => {
          const features = {
            type: "Feature",
            geometry: {
              type: "LineString",
              coordinates: parse_line(item["line"]),
            },
            properties: {
              uuid: item["uuid"],
              city: item["city"],
              country: item["country"],
              delay: item["delay"],
              endnode: item["endnode"],
              length: item["length"],
              level: item["level"],
              pubmillis: item["pubmillis"],
              roadtype: item["roadtype"],
              speed: item["speed"],
              speedkmh: item["speedkmh"],
              startnode: item["startnode"],
              street: item["steet"],
              turntype: item["turntype"],
              type: traduction(item["type"]),
              isirregularity: item["isirregularity"],
              score: item["score"],
              isinfeed : true
            },
          };
          
          jamgeoJsonFeatures.push(features);
        });
        const jamgeoJson = {
          type: "FeatureCollection",
          features: jamgeoJsonFeatures,
        };
        setJamGeoJson(jamgeoJson);
        return JSON.stringify(data, null, 2);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchirregularity = async () => {
    return axios
      .get(
        `${process.env.REACT_APP_BACK_URL}/irregularities?department=${department}`,{headers: {
          Authorization: "Bearer " + props.token,
        },}
      )
      .then(({ data }) => {
        setirregularity(data);
        const irregularitygeoJsonFeatures = [];
        data.forEach = data.map((item, index) => {
          const features = {
            type: "Feature",
            geometry: {
              type: "LineString",
              coordinates: parse_line(item["line"]),
            },
            properties: {
              id: item["id"],
              nThumbsUp: item["nThumbsUp"],
              trend: item["trend"],
              city: item["city"],
              detectionDateMillis: item["detectionDateMillis"],
              type: item["type"],
              endNode: item["endNode"],
              speed: item["speed"],
              seconds: item["seconds"],
              street: item["street"],
              jamlevel: item["jamlevel"],
              country: item["country"],
              ncomments: item["ncomments"],
              highway: item["highway"],
              delayseconds: item["delaySeconds"],
              severity: item["severity"],
              driverscount: item["driverscount"],
              alertscount: item["alertscount"],
              length: item["length"],
              updateDateMillis: item["updateDateMillis"],
              nimages: item["nimages"],
              regularspeed: item["regularSpeed"],
              startnode: item["startnode"],
              causetype: item["causetype"],
              uuid: item["uuid"],
              isactive: item["isactive"],

              isirregularity: item["isirregularity"],
              score: item["score"],
            },
          };

          irregularitygeoJsonFeatures.push(features);
        });
        const irregularitygeoJson = {
          type: "FeatureCollection",
          features: irregularitygeoJsonFeatures,
        };
        setIrregularityGeoJson(irregularitygeoJson);

        return JSON.stringify(data, null, 2);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchdepartment = async () => {
    return axios
      .get(
        `${process.env.REACT_APP_BACK_URL}/departments-contours`,
        {
          headers: {
            Authorization: "Bearer " + props.token,
          },
        }
      )
      .then(({ data }) => {
        // Process data and create GeoJSON features
        data.forEach = data.map((item) => {
          const features = {
            type: "Feature",
            geometry: {
              type: item.geojson_column.type,
              coordinates: item.geojson_column.coordinates,
            },
            properties: {
              id: item["ogc_fid"],
              code: item["code"],
              nom: item["nom"],
            },
          };
          departmentJsonFeatures.push(features);
        });
  
        // Create GeoJSON object
        const departmentgeoJson = {
          type: "FeatureCollection",
          features: departmentJsonFeatures,
        };
  
        setdepartmentGeoJson(departmentgeoJson);
  
        // // Convert the GeoJSON object to a Blob and trigger download
        // const blob = new Blob([JSON.stringify(departmentgeoJson, null, 2)], {
        //   type: "application/json",
        // });
  
        // // Create a link element and trigger the download
        // const link = document.createElement('a');
        // link.href = URL.createObjectURL(blob);
        // link.download = 'department.geojson';
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
  
        return JSON.stringify(data, null, 2);
      })
      .catch((err) => {
        console.error(err);
      });
  };


  

  const fetchanomalies = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACK_URL}/anomalies?department=${department}`,{headers: {
          Authorization: "Bearer " + props.token,
        },}
      );
      const anomalies = response.data;
      anomalies.sort((a, b) => new Date(b.pubMillis) - new Date(a.pubMillis));

      // Traduction
      const translatedAnomalies = anomalies.map((anomaly) => {
        const translatedAnomaly = {
          ...anomaly,
          name: traduction(anomaly.name),
        };
        if (anomaly.info && anomaly.info.reportsubtype) {
          translatedAnomaly.info = {
            ...anomaly.info,
            reportsubtype: traduction(anomaly.info.reportsubtype),
          };
        }

        return translatedAnomaly;
      });
      return translatedAnomalies;
    } catch (error) {
      console.error(error);
    }
  };

  const fetchbrigades = async () => {
    return axios
      .get(
        `${process.env.REACT_APP_BACK_URL}/brigades?department=${department}`,{
          headers: {
            Authorization: "Bearer " + props.token,
          },
        }
      )
      .then(({ data }) => {
        data.forEach = data.map((item, index) => {
          const features = {
            type: "Feature",
            geometry: {
              type: item.geojson_column.type,
              coordinates: item.geojson_column.coordinates,
            },
            properties: {
              id: item["ogc_fid"],
              nb_alerts: item["nb_alerts"],
              moy_alerts: item["moy_alerts"],
              hour: item["hour"],
              reportsubtype: item["reportsubtype"],
              risk: item["risk"],
              competence : item['competence'],
            },
          };
         
          brigadesgeoJsonFeatures.push(features);
        });
        const brigadesgeoJson = {
          type: "FeatureCollection",
          features: brigadesgeoJsonFeatures,
        };
        setbrigadesGeoJson(brigadesgeoJson);

        return JSON.stringify(data, null, 2);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchtipi = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACK_URL}/tipi?department=${department}`, {
        headers: {
          Authorization: "Bearer " + props.token,
        },
      });
      const data = response.data;
      settipi(data);
      const tipigeoJsonFeatures = [];
      data.forEach((item, index) => {
        const [longitude, latitude] = item.location
          .slice(1, -1) // Remove parentheses
          .split(",") // Split at comma
          .map(parseFloat); // Convert strings to floats

        const features = {
          type: "Feature",
          id: index.toString(),
          geometry: {
            type: "Point",
            coordinates: [longitude, latitude],
          },
          properties: {
            id: index.toString(),
            type: item.type,
            dic_vehicule: item.dic_vehicule,
            dic_loc: item.dic_loc,
            direction: item.direction,
            comments: item.comments,
            people_status: item.people_status,
            source: item.source,
            date: item.date,
            road: item.road,
            publisheddate: item.publisheddate,
            order_col: item.order_col,
            location: item.location,
            score: item.score,
            uuid: item.id,
          },
        };

        tipigeoJsonFeatures.push(features);
      });

      const geoJson = {
        type: "FeatureCollection",
        features: tipigeoJsonFeatures,
      };

      settipiGeoJson(geoJson);

      return JSON.stringify(data, null, 2);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDataanalyse = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACK_URL}/current_sum_of_jams_in_km?department=${department}&day=${today}`,{
          headers: {
            Authorization: "Bearer " + props.token,
          },
        }
      );
      setSumlength(response.data[0].distancekm);
      const response_historic = await axios.get(
        `${process.env.REACT_APP_BACK_URL}/current_sum_of_jams_in_km?department=${department}&day=${today}&current=false`,{
          headers: {
            Authorization: "Bearer " + props.token,
          },
        }
      );
      sethistoricSumlength(response_historic.data[0].distancekm);
      // console.log('historicsumlength',historicsumlength)
      // console.log('sumlength',sumlength)

    } catch (error) {
      console.error(error);
    }
  };

  function parse_line(line) {
    // Split the WKT string into individual points
    // Le 11 ici signifie que les 11 premiers characters seront ignorés "LINESTRING(
    const points = line.substring(11).split(",");

    // Extract latitude and longitude from each point
    const coordinates = points.map((point) => {
      const [longitude, latitude] = point.trim().split(" ").map(parseFloat);
      return [longitude, latitude];
    });

    return coordinates;
  }
  

  const handleToggleData = () => {
    setShowData(!showData);
  };

  function convertToUnix(pubmillis) {
    const date = new Date(pubmillis);
    return date.getTime();
  }

  return (
    <div>
      {loading && (
        <BarLoader
          color={"#3587a1"}
          loading={loading}
          size={30}
          aria-label="Loading Spinner"
          data-testid="loader"
          className="barloader"
        />
      )}
      <div className={loading ? "loading" : ""}>
        <Grid container spacing={1} columns={24} className="grande-carte-page">
          <Grid item xs={1}>
            <div className="grande-carte-toolbar">
              <div>
                <button onClick={handleToggleData} className="btn btn-primary">
                  <img
                    src={hamburger}
                    alt="liste"
                    className="fermer_route_img"
                  />
                </button>
              </div>
              <div>
                <button
                  onClick={() => {SaveFilter();navigate("/post-data")}}
                  className="btn btn-primary"
                >
                  <img
                    src={fermer_route}
                    alt="Fermer Route"
                    className="fermer_route_img"
                  />
                </button>
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={23}
            style={{ position: "relative", zIndex: 1, paddingLeft: 0 }}
          >
            <Grande_carte
              ref={containerRef}
              Updatedata={Updatedata}
              mapcoords={mapcoords}
              geoJson={geoJson_filtered}
              tipigeoJson={tipigeoJson_filtered}
              jamgeoJson={jamgeoJson_filtered}
              irregularitygeoJson={irregularitygeoJson_filtered}
              brigadesgeoJson={brigadesgeoJson}
              departmentgeoJson={departmentgeoJson}
              initialviewport={initialviewport}
              setInitialviewport={setInitialviewport}
              viewport={viewport}
              setViewport={setViewport}
              selectedFeature={selectedFeature}
              setSelectedFeature={setSelectedFeature}
              selectedLine={selectedLine}
              setSelectedLine={setSelectedLine}
              department={department}
              analyse={analyse}
              imageMap={imageMap}
              department_number = {department}
              parametres_json={props.parametres_json}
              setParametres_json={props.setParametres_json}
              mapRef={mapRef}
              SaveFilter={SaveFilter}
              clickdata={clickdata}
              setJamGeoJson={setJamGeoJson}
              
            />
          </Grid>

          <Grid item xs={8} style={{ position: "absolute", top: 0, zIndex: 2 }}>
            <Slide in={showData} direction="right" mountOnEnter unmountOnExit>
              <div style={{ overflow: "hidden" }}>
                <Data_grande_carte
                  sumlength={sumlength}
                  historicsumlength={historicsumlength}
                  department={department}
                  clickdata={clickdata}
                  setMapcoords={Updatemapcoords}
                  Updatedata={Updatedata}
                  alerts={alerts}
                  tipi={tipi}
                  jamsdata={jamsdata}
                  irregularity={irregularity}
                  selectedFeature={selectedFeature}
                  setSelectedFeature={setSelectedFeature}
                  anomalies={anomalies}
                  setanomalies={setanomalies}
                  anomalies2={anomalies2}
                  setanomalies2={setanomalies2}
                  handleToggleData={handleToggleData}
                  filter={props.filter}
                  setFilter={props.setFilter}
                  sortbydate={sortbydate}
                  setSortbydate={setSortbydate}
                  imageMap={imageMap}
                  filactuSize={props.parametres_json.filactuSize}
                  token={props.token}
                  parametres_json={props.parametres_json}
                  username={props.username}
                  SaveFilter={SaveFilter}
                  expanded = {expanded}
                  setExpanded = {setExpanded}
                  
                />
              </div>
            </Slide>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Visu_grande_carte;
