import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Stack } from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';

const Data_analyse = ({ department, token }) => {
  const [data, setData] = useState({ historicdata: [], todaydata: [] });
  const [selectedLevels, setSelectedLevels] = useState([1, 2, 3, 4]);

  const today = new Date().getDay();
  const [sumlength, setSumlength] = useState(0);
  

  useEffect(() => {
    if (department) {
      fetchData();
    }
  }, [department, today]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACK_URL}/data_analyse_jams?department=${department}&day=${today}`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      setData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleLevelChange = (level) => {
    setSelectedLevels((prevLevels) =>
      prevLevels.includes(level)
        ? prevLevels.filter((l) => l !== level)
        : [...prevLevels, level]
    );
  };

  const renderCharts = () => {
    const { historicdata, todaydata } = data;

    const charts = [];

    // Filter data for selected levels
    const filteredData = todaydata.filter((item) =>
      selectedLevels.includes(item.level)
    );
    const filteredHistoricData = historicdata.filter((item) =>
      selectedLevels.includes(item.level)
    );

    // Get all unique hours from both today and historic data
    const allHours = Array.from(
      new Set([
        ...filteredData.flatMap((item) => item.hour),
        ...filteredHistoricData.flatMap((item) => item.hour)
      ])
    ).sort((a, b) => a - b).filter(hour => hour >= 0 && hour <= 24);

    // Calculate sum of distances for today's data
    const todaySeries = allHours.map((hour) => {
      const sumDistance = filteredData
        .filter((item) => item.hour === hour)
        .reduce((sum, item) => sum + item.distancekm, 0);
        
      return sumDistance === 0 ? null : sumDistance;
    });

    // Calculate sum of distances for historic data
    const historicSeries = allHours.map((hour) => {
      const sumDistance = filteredHistoricData
        .filter((item) => item.hour === hour)
        .reduce((sum, item) => sum + item.distancekm, 0);
      return sumDistance === 0 ? null : sumDistance;
    });
    
    // Push the chart with summed curves for today and historic data
    charts.push(
      <div key={`chart-${selectedLevels.join('-')}`} className="chart-container">
        <div className="data-analyse-legend">
          <div className="data-analyse-legend-item w-6 h-6 bg-red-500"></div>
          <div className="data-analyse-legend-item">Aujourd'hui</div>
          <div className="data-analyse-legend-item w-6 h-6 bg-blue-400"></div>
          <div className="data-analyse-legend-item">Historique</div>
        </div>
        
        <Stack sx={{ width: '100%', '& .MuiChartsAxis-left .MuiChartsAxis-right .MuiChartsAxis-tickLabel': { fontSize: '1vh !important', }}}>
          <LineChart
            yAxis={[{ label: 'Distance ( km )' }]}
            xAxis={[{ data: allHours.map((hour) => hour.toFixed(1)), label: 'Heure', id: 'xAxis', min: 0, max: 24 }]} // Define an ID for the x-axis and set min and max
            series={[
              {
                name: 'Today',
                data: todaySeries,
                showMark: false,
                label: 'Distance cumulée d\'embouteillage aujourd\'hui ( km )',
                color: '#EF4444'
              },
              {
                name: 'Historic',
                data: historicSeries,
                connectNulls: true, 
                showMark: false,
                label: 'Distance cumulée d\'embouteillage dans l\'historique ( km )',
                valueFormatter: (value) => (value == null ? 'NaN' : value.toString()),
              }
            ]}
            width={450}
            height={350}
            slotProps={{
              legend: { hidden: true },
            }}
          />
        </Stack>
      </div>
    );
    return charts;
  };

  return (
    <div className="page1">
      {renderCharts()}
    </div>
  );
};

export default Data_analyse;
