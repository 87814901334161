import "./App.css";
import React from "react";
import { useEffect, useState } from "react";
import "./index.css";
import "mapbox-gl/dist/mapbox-gl.css";
import Home from "./Home.js";
import Login from "./Login.js";
import PostData from "./PostData.js";
import useToken from "./components/useToken";
import Header_noir from "./components/Header_noir.js";
import Visu_grande_carte from "./Visu_grande_carte.js";




import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

/*const [data,setData] = useState([{}])*/

/*function getDataButtonPressed() {
  const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
  };
  fetch("/api/data", requestOptions)
  .then((response) => console.log(response))
  
}*/
function App() {
  const { token, removeToken, setToken } = useToken();
  //console.log(token)
  //console.log(!token && token !== '' && token !== undefined )
  //Lorsque l'utilisateur arrive sur le site il doit se connecter sur la page login pour obtenir un token
  //Autrement le router renvoie toujours sur la page login
  const [department,setDepartment] = useState('');
  const [username,setUsername] = useState('');
  const [sumlength,setSumlength]=useState(null)
  const [maj, setMaj] = useState(null);
  const [filter, setFilter] = useState({"displayPolice": true, "embouteillage": true, "maxscore": 10, "minscore": 0, "type": ["information", "intemperies", "danger", "accident", "Embouteillage route", "embouteillage", "Route fermée"]});

  const [parametres_json, setParametres_json] = useState({"alertSize": 1, "descriptifSize": 1.25, "filactuSize": 1.25, "heatmapSize": 1, "iconSize": 0.6, "lignSize": 5, "maxscore_popup": 8, "minscore_popup": 4, "showPopup": true});


  //Lorsque l'utilisateur se connecte son nom et son département apparaissent dans le header grace au useeffect ci dessous
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACK_URL}/profile`, {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        
        if (!response.ok) {
          if (response.status === 401 || response.status === 422) {
            console.log("response",response)
            //navigate("/login"); // Use navigate to redirect to "/login"
            console.log("yess")
          } else {
            // Handle other error responses
            console.error("Error fetching profile data:", response.statusText);
          }
          return;
        }

        //définir le département de l'utilisateur
        const data = await response.clone().json();
        //console.log("Department:", data.department);
        setDepartment(data.department)
        setUsername(data.name)

        return await response.clone().json();
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };
    getData();
  }, [token, removeToken]);





  const router = createBrowserRouter([
    
  {
    path: "/visu_grande_carte",
    element: (
        <div>
            <Header_noir setDepartment =  {setDepartment} token={removeToken} department={department} username={username} sumlength={sumlength} maj={maj} filter={filter} parametres_json={parametres_json}/>
            <Visu_grande_carte username={username} token={token} setToken={setToken} setSumlength={setSumlength} setMaj ={setMaj} filter={filter} setFilter={setFilter} parametres_json={parametres_json} setParametres_json={setParametres_json} />
            
        </div>
    ),
},
    {
        path: "/",
        element: (
            <div>
                <Header_noir setDepartment =  {setDepartment} token={removeToken} department={department} username={username} sumlength={sumlength} maj={maj}/>
                <Home />
            </div>
        ),
    },
    {
        path: "/login",
        element: (
            <div>
                <Header_noir setDepartment =  {setDepartment} token={removeToken} department={department} username={username} sumlength={sumlength} maj={maj}/>
                <Login setToken={setToken} />
            </div>
        ),
    },
    {
        path: "/post-data",
        element: (
            <div>
                <Header_noir setDepartment =  {setDepartment} token={removeToken} department={department} username={username} sumlength={sumlength} maj={maj} filter={filter} parametres_json={parametres_json}/>
                <PostData token={token} setToken={setToken} />
            </div>
        ),
    },
]);

  return (
    <div>
    <div>
      <RouterProvider router={router} />
    </div>
    </div>
  );
}
function Index(props) {

  return (
    <div className="relative inline-block">
     
    </div>
  );
};


/*to change charts style refer to mui x charts */

export default App;
