import React from 'react';
import Formulaire from './components/Formulaire';
import { Grid } from '@mui/material';
import Map from './components/Map';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import traductionJson from "./traduction.json";
import axios from "axios";
import BarLoader from "react-spinners/BarLoader";
import RouteBarree from './components/Route_barree';

const PostData = (props) => {
  const [nClickUpdate,setnClickUpdate] = useState(0)

  //Map et Formulaire sont 2 childs du components PostData
  //On envoie donc une fonction updatemarker dans le component map pour recupérer les coordonées puis les envoyer
  //Au component formulaire
  const [department,setDepartment] = useState('')
  const [pinceauclick, setpinceauClick] = useState(true);
  const [Firstrun, setFirstrun] = useState(null);
  const geoJsonFeatures = [];
  const [alerts, setalerts] = useState(null);
  const [priority,setpriority] = useState([false, true])
  const [geoJson, setGeoJson] = useState(null); // pour les embouteillages
    // Remove unused variable
    // const jamgeoJsonFeatures = []; // pour les embouteillages

    const [loading, setLoading] = useState(false);
    const brigadesgeoJsonFeatures = [];
    const departmentJsonFeatures = [];
    const [departmentgeoJson, setdepartmentGeoJson] = useState(null); 
    const [brigadesgeoJson, setbrigadesGeoJson] = useState(null);
    const [marker, setMarker] = useState({
      latitude: 0,
      longitude: 0
    });
    const [viewport, setViewport] = useState({
      latitude: 43.698346,
      longitude: 5.005279,
      zoom: 8,
      width: "100%",
      height: "100vh",
    });

    const updateMarker = (newMarker) => {
      setMarker(newMarker);
    };

    const navigate = useNavigate()

    useEffect(() => {
      const getData = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_BACK_URL}/profile`, {
            method: "GET",
            headers: {
              Authorization: "Bearer " + props.token,
            },
          });
          if (!response.ok) {
            if (response.status === 401 || response.status === 422) {
              navigate("/login"); // Use navigate to redirect to "/login"
            } else {
              // Handle other error responses
              console.error("Error fetching profile data:", response.statusText);
            }
            return;
          }
          
          //définir le département de l'utilisateur
          const data = await response.clone().json();
          //console.log("Department:", data.department);
          setDepartment(data.department)
          return await response.clone().json();
        } catch (error) {
          console.error("Error fetching profile data:", error);
        }
      };
      getData();
    }, []);
    
    useEffect(() => {
      if (department) {
          // Define a function to fetch data
          const fetchData = async () => {
              try {
                  if (Firstrun === null) {
                      setLoading(true); // Set loading to true only during initial fetch
                  }
                  // Fetch data from APIs
                  await fetchdata();
              } catch (error) {
                  console.error('Error fetching data:', error);
              } finally {
                  setLoading(false); // Set loading to false after fetch completes (whether successful or not)
              }
          };

          // Fetch data immediately when component mounts
          fetchData();

          // Set interval to fetch data every 3 minutes
          const intervalId = setInterval(fetchData, 3 * 60 * 1000); // 3 minutes

          // Clear interval when component unmounts or when department changes
          return () => clearInterval(intervalId);
      }
  }, [department]); 

  useEffect(() => {
    if (department) {
      fetchdepartment();
      fetchbrigades();
    }
  }, [department]);

  useEffect(() => {
    if (Firstrun === null) {
      setLoading(true); // Set loading to true when fetching starts
      }
    if (alerts !== null) {
    const newValue = true
    setFirstrun(newValue)
    setLoading(false)}
  }, [alerts]);



    function traduction(type) {
      if (traductionJson.hasOwnProperty(type)) {
        return traductionJson[type];
      } else {
        return type; // Si pas de traduction ne traduit pas
      }
    }

    const fetchdata = async () => {
      return axios
      .get(
        `${process.env.REACT_APP_BACK_URL}/located-data?department=${department}`,
        {
        headers: {
          Authorization: "Bearer " + props.token,
        },
        }
      )
      .then(({ data }) => {
          const translatedData = data.map((item) => ({
            ...item,
            reportsubtype: traduction(item.reportsubtype),
            reporttype: traduction(item.reporttype),
          }));

          // Set the translated data
          setalerts(translatedData);

          data.map((item, index) => {
            const [longitude, latitude] = item.location
              .slice(1, -1) // Remove parentheses
              .split(",") // Split at comma
              .map(parseFloat); // Convert strings to floats

          //Conversion de data en geojson pour pouvoir modifier la carte mapbox
          const features = {
            type: "Feature",
            id: index.toString(),
            geometry: {
              type: "Point",
              coordinates: [longitude, latitude],
            },
            properties: {
              id: index.toString(),
              confidence: item.confidence,
              country: item.country,
              magvar: item.magvar,
              pubMillis: item.pubmillis,
              reliability: item.reliability,
              reportDescription: item.reportDescription,
              reportbymunicipalityuser: item.reportbymunicipalityuser,
              reportrating: item.reportrating,
              reportsubtype: traduction(item.reportsubtype),
              reporttype: traduction(item.reporttype),
              roadtype: item.roadtype,
              uuid: item.uuid,
              isirregularity: item.isirregularity,
              isbrigade: item.isbrigade,
              score:item.score,
            },
          };

          geoJsonFeatures.push(features);
        });

        const geoJson = {
          type: "FeatureCollection",
          features: geoJsonFeatures,
        };

        setGeoJson(geoJson);

        if (Firstrun === null && geoJson.features.length > 0) {
          const firstFeatureCoordinates =
            geoJson.features[0].geometry.coordinates;
          setViewport((prevViewport) => ({
            ...prevViewport,
            longitude: firstFeatureCoordinates[0],
            latitude: firstFeatureCoordinates[1],
            zoom: 8,
          }));
         
          
        }

        return JSON.stringify(data, null, 2);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchdepartment = async () => {
    return axios
      .get(
        `${process.env.REACT_APP_BACK_URL}/departments-contours?department=${department}`,
        {headers: {
          Authorization: "Bearer " + props.token,
        }}
      )
      .then(({ data }) => {
        //setirregularity(data);

        data.forEach = data.map((item, index) => {
          const features = {
            type: "Feature",
            geometry: {
              type: item.geojson_column.type,
              coordinates: item.geojson_column.coordinates,
            },
            properties: {
              id: item["ogc_fid"],
              code: item["code"],
              nom: item["nom"],
            },
          };
          //console.log("irregularity", features.geometry.coordinates);
          departmentJsonFeatures.push(features);
        });
        const departmentgeoJson = {
          type: "FeatureCollection",
          features: departmentJsonFeatures,
        };
        setdepartmentGeoJson(departmentgeoJson);
        // console.log(
        //   departmentgeoJson.features[0].geometry.coordinates[0][0][0][0],
        //   "test"
        // );

        if (
          Array.isArray(
            departmentgeoJson.features[0].geometry.coordinates[0][0][0]
          )
        ) {
          setViewport((prevViewport) => ({
            ...prevViewport,
            longitude:
              departmentgeoJson.features[0].geometry.coordinates[0][0][0][0],
            latitude:
              departmentgeoJson.features[0].geometry.coordinates[0][0][0][1],
            zoom: 8,
          }));
        } else {
          setViewport((prevViewport) => ({
            ...prevViewport,
            longitude:
              departmentgeoJson.features[0].geometry.coordinates[0][0][0],
            latitude:
              departmentgeoJson.features[0].geometry.coordinates[0][0][1],
            zoom: 8,
          }));
        }

        return JSON.stringify(data, null, 2);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchbrigades = async () => {
    return axios
      .get(
        `${process.env.REACT_APP_BACK_URL}/brigades?department=${department}`,
        {headers: {
          Authorization: "Bearer " + props.token,
        }}
      )
      .then(({ data }) => {
        data.forEach = data.map((item, index) => {
          const features = {
            type: "Feature",
            geometry: {
              type: item.geojson_column.type,
              coordinates: item.geojson_column.coordinates,
            },
            properties: {
              id: item["ogc_fid"],
              nb_alerts: item["nb_alerts"],
              moy_alerts: item["moy_alerts"],
              hour: item["hour"],
              reportsubtype: item["reportsubtype"],
              risk: item["risk"],
            },
          };
          //console.log("item", item);
          brigadesgeoJsonFeatures.push(features);
        });
        const brigadesgeoJson = {
          type: "FeatureCollection",
          features: brigadesgeoJsonFeatures,
        };
        setbrigadesGeoJson(brigadesgeoJson);

        return JSON.stringify(data, null, 2);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  

  return (
    <div>
      {loading && <BarLoader
        color={'#3587a1'}
        loading={loading}
        size={30}
        aria-label="Loading Spinner"
        data-testid="loader"
        className="barloader"
      />}
      <div className={loading ? 'loading' : ''}>
    <Grid container spacing={3} style={{marginBottom:50}}>
      <Grid item xs={6}>
      <Map  updateMarker={updateMarker} department={department} geoJson = {geoJson} viewport={viewport}
            setViewport={setViewport} brigadesgeoJson={brigadesgeoJson}
            departmentgeoJson={departmentgeoJson} />
      </Grid>
      <Grid item xs={6}>
        <Formulaire marker={marker} token={props.token} nClickUpdate={nClickUpdate} setnClickUpdate={setnClickUpdate} department={department}/> 
        <RouteBarree priority={priority} setpriority={setpriority} pinceauclick={pinceauclick} setpinceauClick={setpinceauClick} token={props.token} nClickUpdate={nClickUpdate} setnClickUpdate={setnClickUpdate} department={department}/>
      </Grid>
      <Grid item xs={6}>
        
      </Grid>
    </Grid>
    
    </div>
    </div>
      
  );
};

export default PostData;