import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import AnomalyGrandeCarte from "./Anomaly_grande_carte";
import Data_analyse from "./Data_analyse";
import icon_close from "../img/icon_close.svg";
import up_arrow from "../img/up_arrow.svg";
import down_arrow from "../img/down_arrow.svg";
import filtre_icon from "../img/filtre_icon.png";
import sauvegarder from "../img/sauvegarder.png";
import RangeSlider from "./Slider";
import axios from "axios";

export default function Data_grande_carte(props) {
  const [data, setData] = useState([]);
  const [hover, setHover] = useState(false);

  const [data0, setData0] = useState([]);
  const imageStyle = {
    height: `${props.filactuSize * 2}vh`,
    width: `${props.filactuSize * 2}vh`,
    marginRight: "2vh",
  };

  const iconCloseStyle = {
    height: `${props.filactuSize * 3}vh`,
    width: `${props.filactuSize * 3}vh`,
  };

  const circleStyle = {
    height: `${props.filactuSize * 1.5}vh`,
    width: `${props.filactuSize * 1.5}vh`,
  };

  const [showfilter, setShowfilter] = useState(false);
  const [showactualite, setShowactualite] = useState(true);
  const grandeCarteStyle = {
    fontSize: `${props.filactuSize}vh`,
  };

  // useEffect to handle setting data0 and data
  useEffect(() => {
    if (props.irregularity) {
      setData0(props.irregularity[0]);
      setData(props.irregularity);
    }
  }, [props.irregularity]);

  const handleMouseEvent = (event) => {
    setHover(event.type === "mouseenter");
    // console.log('test') 
  };

  const handleChange = () => {
    props.setExpanded(!props.expanded);
  };

  const handleFilterBlock = () => {
    setShowfilter(!showfilter);
  };

  const handleActualite = () => {
    setShowactualite(!showactualite);
  };

  const handleFilter = (type) => {
    props.setFilter((previousState) => {
      const updatedType = [...previousState.type]; // Copying the type array to avoid direct mutation of the state

      // Check if the given type is already present in the type array
      const typeIndex = updatedType.indexOf(type);
      if (typeIndex !== -1) {
        // If the type is already present, remove it from the array
        updatedType.splice(typeIndex, 1);
      } else {
        // Otherwise, add it to the array
        updatedType.push(type);
      }

      return {
        ...previousState,
        type: updatedType, // Update the type array with the modifications
      };
    });
  };

  const handleJamfilter = () => {
    props.setFilter((previousState) => {
      const updatedType = [...previousState.type]; // Copie du tableau type pour éviter la mutation directe de l'état

      // Vérifier si "Embouteillage" est déjà présent dans le tableau type
      const JamIndex = updatedType.indexOf("Embouteillage route");

      if (JamIndex !== -1) {
        // Si "Jam" est déjà présent, le retirer du tableau
        updatedType.splice(JamIndex, 1);
      } else {
        // Sinon, l'ajouter au tableau
        updatedType.push("Embouteillage route");
      }

      return {
        ...previousState,
        type: updatedType, // Mettre à jour le tableau type avec les modifications
      };
    });
    // console.log(props.filter, "FILTER");
  };

  const handleCompetencefilter = () => {
    props.setFilter((previousState) => ({
      ...previousState,
      displayPolice: !previousState.displayPolice, // Access accident directly from previousState
    }));
  };

  const handleSortAnomalies = () => {
    props.setSortbydate(!props.sortbydate);
  };

  useEffect(() => {
    const handleFeed = () => {
      if (props.anomalies) {
        let filteredAnomalies = props.anomalies.slice(); // Copie du tableau des anomalies

        // Filtrer en fonction de minscore
        filteredAnomalies = filteredAnomalies.filter(
          (anomaly) => anomaly.info.score >= props.filter.minscore
        );
        filteredAnomalies = filteredAnomalies.filter(
          (anomaly) => anomaly.info.score <= props.filter.maxscore
        );

        // Filtrer en fonction de displayPolice
        if (
          props.filter.displayPolice !== null &&
          props.filter.displayPolice !== undefined
        ) {
          if (!props.filter.displayPolice) {
            // Si displayPolice est faux, filtrer les anomalies qui ne sont pas des incidents de police
            filteredAnomalies = filteredAnomalies.filter((anomaly) => {
              return anomaly.info.isbrigade === true;
            });
          }
        }

        // Filtrer en fonction de filter.type
        if (props.filter.type) {
          if (!props.filter.type.includes("accident")) {
            filteredAnomalies = filteredAnomalies.filter(
              (anomaly) => anomaly.info.category !== "accident"
            );
          }
          if (!props.filter.type.includes("danger")) {
            filteredAnomalies = filteredAnomalies.filter(
              (anomaly) => anomaly.info.category !== "danger"
            );
          }
          if (!props.filter.type.includes("embouteillage")) {
            filteredAnomalies = filteredAnomalies.filter(
              (anomaly) => anomaly.info.category !== "embouteillage"
            );
          }
          if (!props.filter.type.includes("intemperies")) {
            filteredAnomalies = filteredAnomalies.filter(
              (anomaly) => anomaly.info.category !== "intemperies"
            );
          }
          if (!props.filter.type.includes("information")) {
            filteredAnomalies = filteredAnomalies.filter(
              (anomaly) => anomaly.info.category !== "information"
            );
          }
        }

        // Si sortbydate est true, trier par date, sinon trier par priorité
        if (props.sortbydate) {
          filteredAnomalies.sort(
            (a, b) => new Date(b.pubMillis) - new Date(a.pubMillis)
          );
        } else {
          // Trier par priorité: accident, irregularity, hazard

          filteredAnomalies.sort((a, b) => b.info.score - a.info.score);
        }

        // Mettre à jour la liste filtrée
        props.setanomalies2(filteredAnomalies);
        // console.log(filteredAnomalies,'filtered');
        // console.log(props.filter,'FILTRE CHECK')
      }
    };

    handleFeed();
  }, [
    props.filter.minscore,
    props.filter.type,
    props.filter.maxscore,
    props.filter.displayPolice,
    props.sortbydate,
    props.anomalies,
  ]);

  return (
    <div
      className="grande-carte-DataComponent DataComponent_bigmap"
      style={grandeCarteStyle}
    >
      <div className="maj">
        <Typography className="grande-carte-filtre-title">
          <div className="position-situation-generale">Situation générale</div>
        </Typography>

        <img
          src={icon_close}
          alt="close"
          className="icon-close cursor"
          onClick={props.handleToggleData}
          style={iconCloseStyle}
        />
      </div>

      <Accordion
        className="grande-carte-filtre "
        expanded={props.expanded}
        sx={{
          "&:before": {
            display: "none",
          },
        }}
      >
        <AccordionSummary
          className="panel-header-grande-carte no-cursor"
          expandIcon={
            !props.expanded ? (
              <div className="voir-plus" onClick={handleChange}>
                Voir plus...
              </div>
            ) : null
          }
          aria-controls="panel-content"
          id="fil-actualite-grande-carte"
        >
          <div className="grande-carte-somme-bouchons">
            <img
              src={props.imageMap.reporttype["Embouteillage"]}
              alt="test_round"
              className="icon-popup no-cursor icon-popup-clicked"
              style={imageStyle}
            />
            <p className="grande-carte-bouchons-titre">
              {props.sumlength} km de bouchons
            </p>

            {props.sumlength > props.historicsumlength ? (
              <>
                <img
                  src={up_arrow}
                  alt="close"
                  className="icon-close red-filter cursor"
                  onClick={props.handleToggleData}
                  style={imageStyle}
                  onMouseEnter={handleMouseEvent}
                  onMouseLeave={handleMouseEvent}
                />{" "}
                <br />
                {hover && (
                  <div
                    className={`status-popup2 ${
                      hover ? "opacity-100 scale-100" : "opacity-0 scale-95"
                    }`}
                  >
                    <div className="custom-card">
                      <div className="grid grid-cols-1 gap-4">
                        <a href="#" className="custom-btn2 max-width" style={{marginTop:'1.5rem'}}>
                          <div className="ml-3 mr-3">
                            <p>{`Traffic plus important que la semaine précédente à la même heure (${props.historicsumlength} km)`}</p>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                <img
                  src={down_arrow}
                  alt="close"
                  className="icon-close cursor green-filter"
                  onClick={props.handleToggleData}
                  style={imageStyle}
                  onMouseEnter={handleMouseEvent}
                  onMouseLeave={handleMouseEvent}
                />
                {hover && (
                  <div className={`status-popup2 `}>
                    <div className="custom-card">
                      <div className="grid grid-cols-1 gap-4">
                        <a href="#" className="custom-btn2 max-width" style={{marginTop:'1.5rem'}}>
                          <div className="ml-3 mr-3">
                            <p>{`Traffic moins important que la semaine dernière  à la même heure (${props.historicsumlength} km)`}</p>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </AccordionSummary>

        <Data_analyse department={props.department} token={props.token} />

        <div className="less" onClick={handleChange}>
          Voir moins
        </div>
      </Accordion>

      <Accordion
        className="grande-carte-filtre "
        expanded={showfilter}
        sx={{
          "&:before": {
            display: "none",
          },
        }}
      >
        <AccordionSummary
          className="panel-header-grande-carte no-cursor"
          aria-controls="panel-content"
          id="fil-actualite-grande-carte"
        >
          <div className="grande-carte-container-filtre-icon">
            <Typography className="grande-carte-filtre-title ">
              Fil d'actualité
            </Typography>
            <img
              src={filtre_icon}
              alt="close"
              className="icon-filtre cursor"
              onClick={handleFilterBlock}
              style={imageStyle}
            />
          </div>
        </AccordionSummary>
        <div className="jauge-score-div">
          <RangeSlider setFilter={props.setFilter} filter={props.filter} />{" "}
          <img
            src={sauvegarder}
            alt="close"
            className="icon-filtre cursor"
            onClick={props.SaveFilter}
            style={imageStyle}
          />
        </div>
        <div className="filtrer-et-trier-container">
          <div>
            <p className="center">Type</p>
            <div className="flex items-center mb-4">
              <input
                id="default-checkbox"
                type="checkbox"
                value=""
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                checked={props.filter.type.includes("embouteillage")}
                onClick={() => {
                  handleJamfilter();
                  handleFilter("embouteillage");
                  handleFilter("Route fermée");
                }}
              />
              <label
                htmlFor="default-checkbox"
                className="ms-2  font-medium text-gray-900 dark:text-gray-300"
              >
                Embouteillages
              </label>
            </div>

            <div className="flex items-center mb-4">
              <input
                id="accident-checkbox"
                type="checkbox"
                value=""
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                onClick={() => handleFilter("accident")}
                checked={props.filter.type.includes("accident")}
              />
              <label
                htmlFor="accident-checkbox"
                className="ms-2  font-medium text-gray-900 dark:text-gray-300"
              >
                Accidents
              </label>
            </div>
            <div className="flex items-center mb-4">
              <input
                id="danger-checkbox"
                type="checkbox"
                value=""
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                onClick={() => handleFilter("danger")}
                checked={props.filter.type.includes("danger")}
              />
              <label
                htmlFor="danger-checkbox"
                className="ms-2  font-medium text-gray-900 dark:text-gray-300"
              >
                Dangers
              </label>
            </div>
            <div className="flex items-center mb-4">
              <input
                id="intemperies-checkbox"
                type="checkbox"
                value=""
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                onClick={() => handleFilter("intemperies")}
                checked={props.filter.type.includes("intemperies")}
              />
              <label
                htmlFor="intemperies-checkbox"
                className="ms-2  font-medium text-gray-900 dark:text-gray-300"
              >
                Intemperies
              </label>
            </div>
            <div className="flex items-center mb-4">
              <input
                id="information-checkbox"
                type="checkbox"
                value=""
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                onClick={() => handleFilter("information")}
                checked={props.filter.type.includes("information")}
              />
              <label
                htmlFor="information-checkbox"
                className="ms-2  font-medium text-gray-900 dark:text-gray-300"
              >
                Informations
              </label>
            </div>
            <p className="center">Zone</p>
            <div className="flex items-center mb-4">
              <input
                id="Competence-checkbox"
                type="checkbox"
                value=""
                className="checkbox-round w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800  dark:bg-gray-700 dark:border-gray-600"
                onClick={() => handleCompetencefilter()}
                checked={!props.filter.displayPolice}
                disabled={!props.filter.displayPolice}
              />
              <label
                htmlFor="Competence-checkbox"
                className="ms-2 font-medium text-gray-900 dark:text-gray-300"
              >
                Gendarmerie uniquemment
              </label>
            </div>
            <div className="flex items-center mb-4">
              <input
                id="Competence-checkbox"
                type="checkbox"
                value=""
                className="checkbox-round w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800  dark:bg-gray-700 dark:border-gray-600"
                onClick={() => handleCompetencefilter()}
                checked={props.filter.displayPolice}
                disabled={props.filter.displayPolice}
              />
              <label
                htmlFor="Competence-checkbox"
                className="ms-2 font-medium text-gray-900 dark:text-gray-300"
              >
                Tout
              </label>
            </div>
          </div>
          <div>
            <p className="center bold-title">TRIER PAR</p>
            <div className="flex items-center mb-4">
              <input
                id="les-plus-recent"
                type="checkbox"
                value=""
                className="checkbox-round w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800  dark:bg-gray-700 dark:border-gray-600"
                checked={props.sortbydate}
                onChange={handleSortAnomalies}
                disabled={props.sortbydate}
              />
              <label
                htmlFor="les-plus-recent"
                className="ms-2  font-medium text-gray-900 dark:text-gray-300"
              >
                Les plus récents
              </label>
            </div>
            <div className="flex items-center mb-4">
              <input
                id="les-plus-importants"
                type="checkbox"
                value=""
                className="checkbox-round w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800  dark:bg-gray-700 dark:border-gray-600"
                checked={!props.sortbydate}
                onChange={handleSortAnomalies}
                disabled={!props.sortbydate}
              />
              <label
                htmlFor="les-plus-importants"
                className="ms-2  font-medium text-gray-900 dark:text-gray-300"
              >
                Les plus importants
              </label>
            </div>
          </div>
        </div>
      </Accordion>

      <Accordion
        className="grande-carte-filtre  "
        expanded={showactualite}
        sx={{
          "&:before": {
            display: "none",
          },
        }}
      >
        <AccordionSummary
          className="panel-header-grande-carte no-cursor"
          expandIcon={
            !showactualite ? (
              <div className="voir-plus" onClick={handleActualite}>
                Voir plus...
              </div>
            ) : null
          }
          aria-controls="panel-content"
          id="panel-header-grande-carte"
        >
          {!showactualite &&
            props.anomalies2 &&
            props.anomalies2
              .slice(0, 2) // Limiter les résultats à deux anomalies
              .map((anomaly, index) => (
                <AnomalyGrandeCarte
                  key={`priority_${anomaly.uuid || index}`}
                  anomaly={anomaly}
                  index={index}
                  setMapcoords={props.setMapcoords}
                  data={data}
                  Updatedata={props.Updatedata}
                  alerts={props.alerts}
                  tipi={props.tipi}
                  selectedFeature={props.selectedFeature}
                  setSelectedFeature={props.setSelectedFeature}
                  imageMap={props.imageMap}
                  filactuSize={props.filactuSize}
                  imageStyle={imageStyle}
                  circleStyle={circleStyle}
                />
              ))}

          {showactualite &&
            props.anomalies2 &&
            props.anomalies2
              .slice(0, 500) // Limiter les résultats à 4 anomalies
              .map((anomaly, index) => (
                <AnomalyGrandeCarte
                  key={`priority_${anomaly.uuid || index + 2}`}
                  anomaly={anomaly}
                  index={index + 2}
                  setMapcoords={props.setMapcoords}
                  clickdata={props.clickdata}
                  data={data}
                  Updatedata={props.Updatedata}
                  alerts={props.alerts}
                  tipi={props.tipi}
                  selectedFeature={props.selectedFeature}
                  setSelectedFeature={props.setSelectedFeature}
                  imageMap={props.imageMap}
                  filactuSize={props.filactuSize}
                  imageStyle={imageStyle}
                  circleStyle={circleStyle}
                />
              ))}
        </AccordionSummary>

        <div className="less" onClick={handleActualite}>
          Voir moins
        </div>
      </Accordion>
    </div>
  );
}
