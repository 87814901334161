import { useEffect, useState, useMemo, useCallback } from "react";
import ReactMapGL from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { Marker, Source, Layer } from "react-map-gl";
import { Popup } from "react-map-gl";
import Pin from "../pin";
import { Button } from "@mui/material";
import Geocoder from "./Geocoder";
import { useNavigate } from 'react-router-dom';
import retour from "../img/retour.svg";
import satellite_icon from "../img/satellite_icon.svg"

import mapboxgl from 'mapbox-gl';


const Map = ({ updateMarker, department, geoJson, viewport, setViewport,departmentgeoJson,brigadesgeoJson }) => {

  const [satellitebutton, setSatellitebutton] = useState(false);
  const [proximity1, setProximity1] = useState([-0.566, 44.843]);
  const [mapboxStyleIndex, setMapboxStyleIndex] = useState(0);
  const mapboxStyles = [
    "mapbox://styles/mapbox/navigation-night-v1",
    "mapbox://styles/mapbox/satellite-streets-v12",
  ];

  const navigate = useNavigate()

  //pour filtrer les données affichées sur la carte en fonction de isirregularity
  const [filter1, setfilter1] = useState(true); //
  const [filter2, setfilter2] = useState(true);
  //filtrer les données de la bdd jams a ne as confondre avec const jamdata
  const [jamsData, setJamsData] = useState(true);

  const [markers, setMarkers] = useState([]); // Initialise avec un tableau vide pour stocker les marqueurs
  const [lineCoordinates, setLineCoordinates] = useState(null);


  const departmentCoordinates = {
    "01": [5.332, 46.153], // Coordonnées du département de l'Ain
    "02": [3.494, 49.593], // Coordonnées du département de l'Aisne
    "03": [3.424, 46.196], // Coordonnées du département de l'Allier
    "04": [6.021, 44.059], // Coordonnées du département des Alpes-de-Haute-Provence
    "05": [6.254, 44.749], // Coordonnées du département des Hautes-Alpes
    "06": [7.232, 43.726], // Coordonnées du département des Alpes-Maritimes
    "2A": [8.908, 41.918], // Coordonnées du département de la Corse-du-Sud
    "2B": [9.19, 42.436], // Coordonnées du département de la Haute-Corse
    "07": [4.61, 44.743], // Coordonnées du département de l'Ardèche
    "08": [4.593, 49.499], // Coordonnées du département des Ardennes
    "09": [1.61, 42.992], // Coordonnées du département de l'Ariège
    10: [4.087, 48.031], // Coordonnées du département de l'Aube
    11: [2.304, 43.216], // Coordonnées du département de l'Aude
    12: [2.987, 44.468], // Coordonnées du département de l'Aveyron
    13: [5.087, 43.597], // Coordonnées du département des Bouches-du-Rhône
    14: [-0.587, 49.054], // Coordonnées du département du Calvados
    15: [2.664, 45.129], // Coordonnées du département du Cantal
    16: [0.223, 45.708], // Coordonnées du département de la Charente
    17: [-0.594, 45.716], // Coordonnées du département de la Charente-Maritime
    18: [2.527, 47.083], // Coordonnées du département du Cher
    19: [2.152, 45.268], // Coordonnées du département de la Corrèze
    21: [4.871, 47.353], // Coordonnées du département de la Côte-d'Or
    22: [-2.875, 48.35], // Coordonnées du département des Côtes-d'Armor
    23: [2.14, 46.053], // Coordonnées du département de la Creuse
    24: [0.727, 45.135], // Coordonnées du département de la Dordogne
    25: [6.366, 47.18], // Coordonnées du département du Doubs
    26: [5.031, 44.658], // Coordonnées du département de la Drôme
    27: [1.195, 49.107], // Coordonnées du département de l'Eure
    28: [1.247, 48.636], // Coordonnées du département de l'Eure-et-Loir
    29: [-4.1, 48.298], // Coordonnées du département du Finistère
    30: [4.139, 43.936], // Coordonnées du département du Gard
    31: [1.439, 43.669], // Coordonnées du département de la Haute-Garonne
    32: [0.689, 43.82], // Coordonnées du département du Gers
    33: [-0.566, 44.843], // Coordonnées du département de la Gironde
    34: [3.349, 43.591], // Coordonnées du département de l'Hérault
    35: [-1.752, 48.117], // Coordonnées du département d'Ille-et-Vilaine
    36: [1.515, 46.769], // Coordonnées du département de l'Indre
    37: [0.811, 47.213], // Coordonnées du département de l'Indre-et-Loire
    38: [5.408, 45.396], // Coordonnées du département de l'Isère
    39: [5.778, 46.649], // Coordonnées du département du Jura
    40: [-0.625, 43.857], // Coordonnées du département des Landes
    41: [1.411, 47.423], // Coordonnées du département du Loir-et-Cher
    42: [4.124, 45.686], // Coordonnées du département de la Loire
    43: [3.948, 45.149], // Coordonnées du département de la Haute-Loire
    44: [-1.676, 47.348], // Coordonnées du département de la Loire-Atlantique
    45: [2.14, 47.998], // Coordonnées du département du Loiret
    46: [1.638, 44.665], // Coordonnées du département du Lot
    47: [0.616, 44.308], // Coordonnées du département du Lot-et-Garonne
    48: [3.265, 44.479], // Coordonnées du département de la Lozère
    49: [-0.548, 47.475], // Coordonnées du département de Maine-et-Loire
    50: [-1.434, 49.115], // Coordonnées du département de la Manche
    51: [4.083, 49.017], // Coordonnées du département de la Marne
    52: [5.159, 48.138], // Coordonnées du département de la Haute-Marne
    53: [-0.743, 48.229], // Coordonnées du département de la Mayenne
    54: [6.351, 48.696], // Coordonnées du département de la Meurthe-et-Moselle
    55: [5.372, 48.845], // Coordonnées du département de la Meuse
    56: [-2.829, 47.766], // Coordonnées du département du Morbihan
    57: [6.482, 49.039], // Coordonnées du département de la Moselle
    58: [3.599, 47.004], // Coordonnées du département de la Nièvre
    59: [3.126, 50.694], // Coordonnées du département du Nord
    60: [2.328, 49.42], // Coordonnées du département de l'Oise
    61: [0.073, 48.532], // Coordonnées du département de l'Orne
    62: [2.394, 50.489], // Coordonnées du département du Pas-de-Calais
    63: [3.073, 45.648], // Coordonnées du département du Puy-de-Dôme
    64: [-0.623, 43.248], // Coordonnées du département des Pyrénées-Atlantiques
    65: [0.097, 43.136], // Coordonnées du département des Hautes-Pyrénées
    66: [2.66, 42.659], // Coordonnées du département des Pyrénées-Orientales
    67: [7.487, 48.573], // Coordonnées du département du Bas-Rhin
    68: [7.275, 47.964], // Coordonnées du département du Haut-Rhin
    69: [4.841, 45.759], // Coordonnées du département du Rhône
    70: [6.217, 47.637], // Coordonnées du département de la Haute-Saône
    71: [4.857, 46.662], // Coordonnées du département de la Saône-et-Loire
    72: [0.326, 48.253], // Coordonnées du département de la Sarthe
    73: [6.364, 45.568], // Coordonnées du département de la Savoie
    74: [6.552, 46.066], // Coordonnées du département de la Haute-Savoie
    75: [2.3522, 48.8566], // Coordonnées du département de Paris
    76: [0.933, 49.453], // Coordonnées du département de la Seine-Maritime
    77: [2.668, 48.601], // Coordonnées du département de la Seine-et-Marne
    78: [2.114, 48.818], // Coordonnées du département des Yvelines
    79: [-0.629, 46.627], // Coordonnées du département des Deux-Sèvres
    80: [2.289, 49.881], // Coordonnées du département de la Somme
    81: [1.734, 43.94], // Coordonnées du département du Tarn
    82: [1.408, 44.076], // Coordonnées du département de Tarn-et-Garonne
    83: [6.332, 43.401], // Coordonnées du département du Var
    84: [5.078, 43.943], // Coordonnées du département du Vaucluse
    85: [-1.394, 46.721], // Coordonnées du département de la Vendée
    86: [0.646, 46.582], // Coordonnées du département de la Vienne
    87: [1.241, 45.86], // Coordonnées du département de la Haute-Vienne
    88: [6.425, 48.173], // Coordonnées du département des Vosges
    89: [3.591, 47.81], // Coordonnées du département de l'Yonne
    90: [6.841, 47.634], // Coordonnées du département du Territoire de Belfort
    91: [2.197, 48.582], // Coordonnées du département de l'Essonne
    92: [2.238, 48.904], // Coordonnées du département des Hauts-de-Seine
    93: [2.351, 48.918], // Coordonnées du département de la Seine-Saint-Denis
    94: [2.47, 48.79], // Coordonnées du département du Val-de-Marne
    95: [2.169, 49.055], // Coordonnées du département du Val-d'Oise
  };

  useEffect(() => {
    if (
      department &&
      departmentCoordinates.hasOwnProperty(department)
    ) {
      setProximity1(departmentCoordinates[department]);
    }
  }, [department]);

  


  const [hoverInfo, setHoverInfo] = useState(null);

  

  //On utilise useMemo pour se souvenir de la valeur affiché en popup car sinon l'application va etre très lente
  //car par example si on passe 3 fois sur le meme point a la suite on doit calculer 3 fois la valeur affichée
  //cela facilite grandement le rendering
  const memoizedReportSubtype = useMemo(() => {
    return hoverInfo ? hoverInfo.reportsubtype : "";
  }, [hoverInfo]);

  //pareil on utilise usememo pour ne pas avoir a recalculer les coordonées mais je ne suis pas sur que ce soit nécéssaire
  const memoizedlatitude = useMemo(() => {
    return hoverInfo ? hoverInfo.latitude : "";
  }, [hoverInfo]);

  const memoizedlongitude = useMemo(() => {
    return hoverInfo ? hoverInfo.longitude : "";
  }, [hoverInfo]);

  const handleMouseLeave = useCallback(() => {
    setHoverInfo(null); // Clear hoverInfo when mouse leaves the map
  }, []);


  const handleMapClick = (e) => {
    e.preventDefault();
    const newMarker = {
      longitude: e.lngLat.lng,
      latitude: e.lngLat.lat,
    };
    //console.log(newMarker);

    const updatedMarkers =
      markers.length < 15 ? [...markers, newMarker] : [...markers];
    setMarkers(updatedMarkers);
    //console.log(updatedMarkers);
    updateMarker(Polyline(updatedMarkers));
    updateLineCoordinates(updatedMarkers);
  };

  const Polyline = (variable) => {
    return `${variable
      .map((marker) => `${marker.longitude} ${marker.latitude}`)
      .join(" ")}`;
  };

  const handleMarkerDragEnd = (e, index) => {
    const updatedMarkers = markers.map((marker, i) => {
      if (i === index) {
        return { ...marker, longitude: e.lngLat.lng, latitude: e.lngLat.lat };
      }
      return marker;
    });

    setMarkers(updatedMarkers);
    updateMarker(Polyline(updatedMarkers));
    updateLineCoordinates(updatedMarkers);
  };

  const updateLineCoordinates = (markers) => {
    if (markers.length < 2) {
      setLineCoordinates(null);
      return;
    }
    const coordinates = markers.map((marker) => [
      marker.longitude,
      marker.latitude,
    ]);
    setLineCoordinates({
      type: "Feature",
      geometry: {
        type: "LineString",
        coordinates: coordinates,
      },
    });
  };

  const clearMarkers = () => {
    setMarkers([]);
    setLineCoordinates(null); // Clear line data
  };


  //changer la vue satellite
  const handleStyleChange = () => {
    setMapboxStyleIndex((prevIndex) => (prevIndex === 0 ? 1 : 0));
  };

  const handleStyleLoad = (event) => {
    const map = event.target;
    map.getCanvas().style.cursor = 'default';
  
  }
    

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "88vh" }}>
      <div>
       <img
                src={retour}
                alt="retour"
                className="retour_img cursor"
                onClick={() => { navigate("/visu_grande_carte") }}
            />
          </div>
      <ReactMapGL
      onLoad={handleStyleLoad}
        {...viewport}
        mapStyle={mapboxStyles[mapboxStyleIndex]}
        mapboxAccessToken="pk.eyJ1IjoiY2hhaDYwNDg4IiwiYSI6ImNsc2E3ZmR2bjAwOHUybG81dzE0c24ybnYifQ.APsYLkryQsu19baQz4y0bg" // Utilisez votre token Mapbox ici
        interactiveLayerIds={["jam", "road_closed", "hazard"]} // cette ligne permet de créer e.features lorsque la souris passe par dessus d'un layer avec id="data"
        onViewportChange={(nextViewport) => setViewport(nextViewport)}
        onDblClick={handleMapClick}
        onMouseLeave={handleMouseLeave} // Add onMouseLeave event handler
        onMove={(evt) => {
          setViewport(evt.viewport);
        }}
        onMouseMove={(e) => {
          if (e.features && e.features.length > 0) {
            // On verifie que la souris est bien au dessus d'un layer (un élément de la carte)
            //console.log(e.features);
            const feature = e.features[0] && e.features;
            //La ligne précédente permet d'accéder a toutes les variables du layer sur lequel passe la souris
            //ici le layer représente une alerte, on peut donc accéder a ses coordonées sur la carte ect
            setHoverInfo({
              longitude: e.lngLat.lng,
              latitude: e.lngLat.lat,
              reportsubtype: e.features[0].properties.reportsubtype,
            });
          }
        }}
      >
        <div>
          {/* <div className="container-toggle">
            
            <label
              className="flex items-center space-x-2"
              onMouseEnter={ToggleEnter2}
              onMouseLeave={ToggleLeave2}
            >
              <div className="relative z-index">
                {satellitebutton && (
                  <div className="inline-block text-sm font-medium text-gray-900 bg-gray-200 dark:text-white dark:bg-gray-700 rounded-lg shadow-sm p-1">
                    Satellite
                  </div>
                )}
              </div>
              <div className="relative">
                <input
                  onClick={handleStyleChange}
                  type="checkbox"
                  value=""
                  className="sr-only peer"
                />
                <div className="map-button relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              </div>
              
            </label>
          </div> */}
        </div>

        <Source
          id="points-data"
          type="geojson"
          data={geoJson}
          promoteId="id"
          generateId={true}
        >
     
            <Layer
              id="jam"
              type="circle"
              paint={{
                "circle-color": "yellow",
                "circle-radius": ["get", "size"],
              }}
              
            />
          

          
            
          
        </Source>

        <Source
            id="department-data"
            type="geojson"
            data={departmentgeoJson}
            promoteId="id"
            generateId={true}
          >
            <Layer
              id="department-layer"
              type="line"
              paint={{
                "line-color": "black",
                "line-opacity": 1,
                "line-width": 2,
                "line-dasharray": [2, 2], // Adjust the dash array as needed
              }}
              
            />
          </Source>
          <Source
            id="polygon-data"
            type="geojson"
            data={brigadesgeoJson}
            promoteId="id"
          >
            <Layer
              id="brigades-layer"
              type="line"
              paint={{
                "line-color": "white",
                "line-opacity": 0.3,
                "line-width": 2,
              }}
              
            />
          </Source>

        {hoverInfo && (
          <Popup
            longitude={memoizedlongitude}
            latitude={memoizedlatitude}
            offsetTop={[0, -10]}
            closeButton={false}
            closeOnClick={false}
            className="county-info"
          >
            {memoizedReportSubtype}
          </Popup>
        )}

        {markers.map((marker, index) => (
          <Marker
            key={index}
            longitude={marker.longitude}
            latitude={marker.latitude}
            anchor="bottom"
            draggable
            onDragEnd={(e) => handleMarkerDragEnd(e, index)}
          >
            <div>
              <Pin size={20} />
              <div
                style={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                  marginTop: "-27px",
                  marginLeft: "6px",
                  marginBot: "10px",
                }}
              >
                {index + 1}
              </div>
            </div>
          </Marker>
        ))}

        {lineCoordinates && (
          <Source id="lineSource" type="geojson" data={lineCoordinates}>
            <Layer
              id="lineLayer"
              type="line"
              source="lineSource"
              layout={{
                "line-join": "round",
                "line-cap": "round",
              }}
              paint={{
                "line-color": "#888",
                "line-width": 3,
              }}
            />
          </Source>
        )}
        {department && proximity1 && (
            <Geocoder department={department} proximity1={proximity1} />
          )}

<div className="button-effacer-marqueurs">
      <Button className="button-marqueurs" variant="contained" onClick={clearMarkers}>
          Effacer les marqueurs
        </Button></div>
        
    
        <div className="button-retour-carte">
      
        
          <img
                src={satellite_icon}
                alt="retour"
                className="fermer_route_img cursor"
                onClick={handleStyleChange}
            />
        </div>

      </ReactMapGL>
      

    
    </div>
  );
};

export default Map;
