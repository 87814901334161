import { useEffect, useState, useMemo, useCallback, useRef } from "react";
import ReactMapGL from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { Source, Layer } from "react-map-gl";
import React from "react";
import { Popup } from "react-map-gl";
import Geocoder from "./Geocoder";
import CommuteIcon from "@mui/icons-material/Commute";
import { renderToString } from "react-dom/server";
import * as turf from "@turf/turf";
import Jauge_score_popup from "./Jauge_score_popup";

import Danger_round from "../img/danger_round.png";
import route_barree_round from "../img/route_barree_round.png";
import Accident_round from "../img/accident_round.png";
import embouteillage_round from "../img/embouteillage_round.png";
import neige_round from "../img/neige_round.png";
import obstacle_round from "../img/obstacle_round.png";
import feu_round from "../img/feu_round.png";
import voiture_arretee_round from "../img/voiture_arretee_round.png";
import chantier_round from "../img/chantier_round.png";
import meteo_round from "../img/meteo_round.png";

import sauvegarder from "../img/sauvegarder.png";
import zoom from "../img/zoom.svg";
import unzoom from "../img/unzoom.svg";
import reset from "../img/reset.svg";
import Parametres_icon from "../img/Parametres_icon.png";
import { Slide, colors } from "@mui/material";
import icon_close from "../img/icon_close.svg";
import Slider from "@mui/material/Slider";
import mapboxgl from 'mapbox-gl';


const Grande_carte = (props) => {
  const [dashArray, setDashArray] = useState([0, 4, 3]);

  useEffect(() => {
    const dashArraySequence = [
      [0, 4, 3],
      [0.5, 4, 2.5],
      [1, 4, 2],
      [1.5, 4, 1.5],
      [2, 4, 1],
      [2.5, 4, 0.5],
      [3, 4, 0],
      [0, 0.5, 3, 3.5],
      [0, 1, 3, 3],
      [0, 1.5, 3, 2.5],
      [0, 2, 3, 2],
      [0, 2.5, 3, 1.5],
      [0, 3, 3, 1],
      [0, 3.5, 3, 0.5],
    ];

    let step = 0;

    const intervalId = setInterval(() => {
      setDashArray(dashArraySequence[step]);
      step = (step + 1) % dashArraySequence.length;
    }, 100); // Update every 100ms for smoother animation

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  const [opacity, setOpacity] = useState(0.5);

  useEffect(() => {
    const interval = setInterval(() => {
      setOpacity((prevOpacity) => (prevOpacity === 0.5 ? 1 : 0.5));
    }, 750); // Change opacity every second

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  const fourMinutesAgo = Date.now() - 6 * 60 * 1000;

  const [showpolice, setShowPolice] = useState(true);

  const [visibleIrregularityPopups, setVisibleIrregularityPopups] = useState(
    []
  );
  const [visiblePopups, setVisiblePopups] = useState([]);
  const grandeCarteStyle = {
    fontSize: `${props.parametres_json.filactuSize}vh`,
  };
  const [PopupStyle, setPopupStyle] = useState({
    fontSize: `${props.parametres_json.descriptifSize}vh`,
    lineHeight: `${props.parametres_json.descriptifSize * 1.25}vh`,
    color: "",
  });

  const [circleStyle,setCircleStyle] = useState({
    height: `${props.parametres_json.descriptifSize * 1.75}vh`,
    width: `${props.parametres_json.descriptifSize * 1.75}vh`,
  });

  const imageStyle = {
    height: `${props.parametres_json.descriptifSize * 2}vh`,
    width: `${props.parametres_json.descriptifSize * 2}vh`,
  };

  const [styleCarte, setstyleCarte] = useState(1);
  const [showParametres, setShowParametres] = useState(false);
  const [hoverInfo, setHoverInfo] = useState(null);
  const [reportsubtype, setreportsubtype] = useState([]);
  const [mapboxStyles, setmapboxStyles] = useState([
    "mapbox://styles/mapbox/dark-v11",
    "mapbox://styles/mapbox/satellite-streets-v12",
  ]);
  const [mapboxStyleIndex, setMapboxStyleIndex] = useState(0);
  const [showlayer, setShowLayer] = useState("points");

  const [proximity1, setProximity1] = useState([-0.566, 44.843]);
  // const [heatmapbutton, setHeatmapbutton] = useState(false);
  const departmentCoordinates = {
    "01": [5.332, 46.153], // Coordonnées du département de l'Ain
    "02": [3.494, 49.593], // Coordonnées du département de l'Aisne
    "03": [3.424, 46.196], // Coordonnées du département de l'Allier
    "04": [6.021, 44.059], // Coordonnées du département des Alpes-de-Haute-Provence
    "05": [6.254, 44.749], // Coordonnées du département des Hautes-Alpes
    "06": [7.232, 43.726], // Coordonnées du département des Alpes-Maritimes
    "2A": [8.908, 41.918], // Coordonnées du département de la Corse-du-Sud
    "2B": [9.19, 42.436], // Coordonnées du département de la Haute-Corse
    "07": [4.61, 44.743], // Coordonnées du département de l'Ardèche
    "08": [4.593, 49.499], // Coordonnées du département des Ardennes
    "09": [1.61, 42.992], // Coordonnées du département de l'Ariège
    10: [4.087, 48.031], // Coordonnées du département de l'Aube
    11: [2.304, 43.216], // Coordonnées du département de l'Aude
    12: [2.987, 44.468], // Coordonnées du département de l'Aveyron
    13: [5.087, 43.597], // Coordonnées du département des Bouches-du-Rhône
    14: [-0.587, 49.054], // Coordonnées du département du Calvados
    15: [2.664, 45.129], // Coordonnées du département du Cantal
    16: [0.223, 45.708], // Coordonnées du département de la Charente
    17: [-0.594, 45.716], // Coordonnées du département de la Charente-Maritime
    18: [2.527, 47.083], // Coordonnées du département du Cher
    19: [2.152, 45.268], // Coordonnées du département de la Corrèze
    21: [4.871, 47.353], // Coordonnées du département de la Côte-d'Or
    22: [-2.875, 48.35], // Coordonnées du département des Côtes-d'Armor
    23: [2.14, 46.053], // Coordonnées du département de la Creuse
    24: [0.727, 45.135], // Coordonnées du département de la Dordogne
    25: [6.366, 47.18], // Coordonnées du département du Doubs
    26: [5.031, 44.658], // Coordonnées du département de la Drôme
    27: [1.195, 49.107], // Coordonnées du département de l'Eure
    28: [1.247, 48.636], // Coordonnées du département de l'Eure-et-Loir
    29: [-4.1, 48.298], // Coordonnées du département du Finistère
    30: [4.139, 43.936], // Coordonnées du département du Gard
    31: [1.439, 43.669], // Coordonnées du département de la Haute-Garonne
    32: [0.689, 43.82], // Coordonnées du département du Gers
    33: [-0.566, 44.843], // Coordonnées du département de la Gironde
    34: [3.349, 43.591], // Coordonnées du département de l'Hérault
    35: [-1.752, 48.117], // Coordonnées du département d'Ille-et-Vilaine
    36: [1.515, 46.769], // Coordonnées du département de l'Indre
    37: [0.811, 47.213], // Coordonnées du département de l'Indre-et-Loire
    38: [5.408, 45.396], // Coordonnées du département de l'Isère
    39: [5.778, 46.649], // Coordonnées du département du Jura
    40: [-0.625, 43.857], // Coordonnées du département des Landes
    41: [1.411, 47.423], // Coordonnées du département du Loir-et-Cher
    42: [4.124, 45.686], // Coordonnées du département de la Loire
    43: [3.948, 45.149], // Coordonnées du département de la Haute-Loire
    44: [-1.676, 47.348], // Coordonnées du département de la Loire-Atlantique
    45: [2.14, 47.998], // Coordonnées du département du Loiret
    46: [1.638, 44.665], // Coordonnées du département du Lot
    47: [0.616, 44.308], // Coordonnées du département du Lot-et-Garonne
    48: [3.265, 44.479], // Coordonnées du département de la Lozère
    49: [-0.548, 47.475], // Coordonnées du département de Maine-et-Loire
    50: [-1.434, 49.115], // Coordonnées du département de la Manche
    51: [4.083, 49.017], // Coordonnées du département de la Marne
    52: [5.159, 48.138], // Coordonnées du département de la Haute-Marne
    53: [-0.743, 48.229], // Coordonnées du département de la Mayenne
    54: [6.351, 48.696], // Coordonnées du département de la Meurthe-et-Moselle
    55: [5.372, 48.845], // Coordonnées du département de la Meuse
    56: [-2.829, 47.766], // Coordonnées du département du Morbihan
    57: [6.482, 49.039], // Coordonnées du département de la Moselle
    58: [3.599, 47.004], // Coordonnées du département de la Nièvre
    59: [3.126, 50.694], // Coordonnées du département du Nord
    60: [2.328, 49.42], // Coordonnées du département de l'Oise
    61: [0.073, 48.532], // Coordonnées du département de l'Orne
    62: [2.394, 50.489], // Coordonnées du département du Pas-de-Calais
    63: [3.073, 45.648], // Coordonnées du département du Puy-de-Dôme
    64: [-0.623, 43.248], // Coordonnées du département des Pyrénées-Atlantiques
    65: [0.097, 43.136], // Coordonnées du département des Hautes-Pyrénées
    66: [2.66, 42.659], // Coordonnées du département des Pyrénées-Orientales
    67: [7.487, 48.573], // Coordonnées du département du Bas-Rhin
    68: [7.275, 47.964], // Coordonnées du département du Haut-Rhin
    69: [4.841, 45.759], // Coordonnées du département du Rhône
    70: [6.217, 47.637], // Coordonnées du département de la Haute-Saône
    71: [4.857, 46.662], // Coordonnées du département de la Saône-et-Loire
    72: [0.326, 48.253], // Coordonnées du département de la Sarthe
    73: [6.364, 45.568], // Coordonnées du département de la Savoie
    74: [6.552, 46.066], // Coordonnées du département de la Haute-Savoie
    75: [2.3522, 48.8566], // Coordonnées du département de Paris
    76: [0.933, 49.453], // Coordonnées du département de la Seine-Maritime
    77: [2.668, 48.601], // Coordonnées du département de la Seine-et-Marne
    78: [2.114, 48.818], // Coordonnées du département des Yvelines
    79: [-0.629, 46.627], // Coordonnées du département des Deux-Sèvres
    80: [2.289, 49.881], // Coordonnées du département de la Somme
    81: [1.734, 43.94], // Coordonnées du département du Tarn
    82: [1.408, 44.076], // Coordonnées du département de Tarn-et-Garonne
    83: [6.332, 43.401], // Coordonnées du département du Var
    84: [5.078, 43.943], // Coordonnées du département du Vaucluse
    85: [-1.394, 46.721], // Coordonnées du département de la Vendée
    86: [0.646, 46.582], // Coordonnées du département de la Vienne
    87: [1.241, 45.86], // Coordonnées du département de la Haute-Vienne
    88: [6.425, 48.173], // Coordonnées du département des Vosges
    89: [3.591, 47.81], // Coordonnées du département de l'Yonne
    90: [6.841, 47.634], // Coordonnées du département du Territoire de Belfort
    91: [2.197, 48.582], // Coordonnées du département de l'Essonne
    92: [2.238, 48.904], // Coordonnées du département des Hauts-de-Seine
    93: [2.351, 48.918], // Coordonnées du département de la Seine-Saint-Denis
    94: [2.47, 48.79], // Coordonnées du département du Val-de-Marne
    95: [2.169, 49.055], // Coordonnées du département du Val-d'Oise
  };
  const commuteIconImage = new Image();
  commuteIconImage.src = `data:image/svg+xml,${encodeURIComponent(
    renderToString(<CommuteIcon />)
  )}`;

  useEffect(() => {
    // Check if props.geoJson, props.jamgeoJson, props.irregularitygeoJson, and props.Updatedata exist before using them
    if (
      props.geoJson &&
      props.jamgeoJson &&
      props.irregularitygeoJson &&
      props.Updatedata &&
      props.brigadesGeoJson
    ) {
      setreportsubtype([
        ...new Set(
          props.geoJson.features.map(
            (feature) => feature.properties.reportsubtype
          )
        ),
      ]);
    } else {
    }
  }, [
    props.geoJson,
    props.jamgeoJson,
    props.irregularitygeoJson,
    props.Updatedata,
    props.selectedFeature,
    props.selectedLine,
    props.brigadesgeoJson,
  ]);

  useEffect(() => {
    if (
      props.department &&
      departmentCoordinates.hasOwnProperty(props.department)
    ) {
      setProximity1(departmentCoordinates[props.department]);
      // console.log(departmentCoordinates,'departmentCoordinates')
    }
  }, [props.department]);

  useEffect(() => {
    if (props.mapRef.current != null && props.tipigeoJson) {
      props.mapRef.current.getSource("tipi").setData(props.tipigeoJson);
      // console.log("Tipi Data Updated");
      // console.log(props.tipigeoJson,'is tipi empty ?')
      // Determine which popups should be visible based on the updated geojson
      const newVisiblePopups = props.tipigeoJson.features
        .filter((feature) => {
          return (
            feature.properties.score > 3 && feature.properties.isinfeed === true
          );
        })
        .map((feature) => feature.id); // Assuming 'id' is a unique identifier for features

      setVisiblePopups(newVisiblePopups);
    }
  }, [props.tipigeoJson]);

  useEffect(() => {
    if (props.mapRef.current != null && props.geoJson) {
      props.mapRef.current.getSource("points-data").setData(props.geoJson);
    }
  }, [props.geoJson]);

  useEffect(() => {
    if (props.mapRef.current != null && props.departmentgeoJson) {
      const departmentFeature = props.departmentgeoJson.features.find(
        (feature) => feature.properties.code === props.department
      );

      if (departmentFeature) {
        // Calculate the center of the department polygon
        const center = turf.center(departmentFeature).geometry.coordinates;

        // Fly to the center of the polygon
        props.mapRef.current.flyTo({ center: center });
        props.setInitialviewport((prevViewport) => ({
          ...prevViewport,
          longitude: center[0],
          latitude: center[1],
          zoom: 9,
        }));
      }
    }
  }, [props.departmentgeoJson]);

  useEffect(() => {
    if (props.mapRef.current != null && props.jamgeoJson) {
      props.mapRef.current.getSource("line-data").setData(props.jamgeoJson);
      // console.log("Jam Data Updated");
    }
  }, [props.jamgeoJson]);

  useEffect(() => {
    if (props.mapRef.current != null && props.irregularitygeoJson) {
      props.mapRef.current
        .getSource("line-data2")
        .setData(props.irregularitygeoJson);
      // Determine which popups should be visible for irregularitygeoJson
      const newVisibleIrregularityPopups = props.irregularitygeoJson.features
        .filter((feature) => {
          return (
            feature.properties.score > 0 && feature.properties.isinfeed === true
          );
        })
        .map((feature) => feature.properties.uuid); // Assuming 'id' is a unique identifier for features

      setVisibleIrregularityPopups(newVisibleIrregularityPopups);
    }
  }, [props.irregularitygeoJson]);

  useEffect(() => {
    let newStyle;

    if (styleCarte === "Theme noir") {
      newStyle = "mapbox://styles/mapbox/dark-v11";
    } else if (styleCarte === "Theme navigation") {
      newStyle = "mapbox://styles/mapbox/navigation-night-v1";
    } else if (styleCarte === "Theme blanc") {
      newStyle = "mapbox://styles/mapbox/navigation-day-v1";
    } else if (styleCarte === "Satellite") {
      newStyle = "mapbox://styles/mapbox/satellite-v9";
    } else if (styleCarte === "Theme vert") {
      newStyle = "mapbox://styles/mapbox/streets-v12";
    } else if (styleCarte === "Satellite street") {
      newStyle = "mapbox://styles/mapbox/satellite-streets-v12";
    }

    if (newStyle) {
      setmapboxStyles([newStyle, ...mapboxStyles.slice(1)]);
    }
  }, [styleCarte]);

  useEffect(() => {
    const button = document.getElementById("dropdownDefaultButton");
    const dropdownMenu = document.getElementById("dropdown");

    const toggleDropdown = () => {
      dropdownMenu.classList.toggle("hidden");
      dropdownMenu.classList.toggle("block");
    };

    button.addEventListener("click", toggleDropdown);

    // Cleanup event listener on component unmount
    return () => {
      button.removeEventListener("click", toggleDropdown);
    };
  }, []);

  

  //filtrer tous les signalements à la fois

  //On utilise useMemo pour se souvenir de la valeur affiché en popup car sinon l'application va etre très lente
  //car par example si on passe 3 fois sur le meme point a la suite on doit calculer 3 fois la valeur affichée
  //cela facilite grandement le rendering
  const memorizedsource = useMemo(() => {
    return hoverInfo ? hoverInfo.source : "";
  }, [hoverInfo]);

  const memorizedReporttype = useMemo(() => {
    return hoverInfo ? hoverInfo.reporttype : "";
  }, [hoverInfo]);

  //pareil on utilise usememo pour ne pas avoir a recalculer les coordonées mais je ne suis pas sur que ce soit nécéssaire
  const memorizedlatitude = useMemo(() => {
    return hoverInfo ? hoverInfo.latitude : "";
  }, [hoverInfo]);

  const memorizedlongitude = useMemo(() => {
    return hoverInfo ? hoverInfo.longitude : "";
  }, [hoverInfo]);

  const memorizedpubmillis = useMemo(() => {
    return hoverInfo ? hoverInfo.pubmillis : "";
  }, [hoverInfo]);

  const memorizedtype = useMemo(() => {
    return hoverInfo ? hoverInfo.reporttype : "";
  }, [hoverInfo]);

  const memorizedsubtype = useMemo(() => {
    return hoverInfo ? hoverInfo.reportsubtype : "";
  }, [hoverInfo]);

  const memorizedscore = useMemo(() => {
    return hoverInfo ? hoverInfo.score : "";
  }, [hoverInfo]);

  const memorizedlength = useMemo(() => {
    return hoverInfo ? hoverInfo.length : "";
  }, [hoverInfo]);

  const memorizedspeed = useMemo(() => {
    return hoverInfo ? hoverInfo.speed : "";
  }, [hoverInfo]);

  const memorizedlevel = useMemo(() => {
    return hoverInfo ? hoverInfo.level : "";
  }, [hoverInfo]);

  const handleMouseLeave = useCallback(() => {
    setHoverInfo(null); // Clear hoverInfo when mouse leaves the map
  }, []);

  const handleMapClick = (e) => {
    if (e.features && e.features.length > 0) {
      const feature = e.features[0] && e.features;
      //console.log("updatedata", e.features[0].properties);
      props.Updatedata(e.features[0].properties);
      props.setSelectedFeature(feature[0].properties.uuid);
      props.setSelectedLine(feature[0].properties.id);
      // console.log("reportsubtype", e.features[0].properties);
    }
  };

  const minutesAgo = (timestamp) => {
    const timestampDt = new Date(timestamp);
    const currentDt = new Date();
    const diffMs = currentDt - timestampDt;
    const diffMinutes = Math.floor(diffMs / (1000 * 60));

    if (diffMinutes >= 1440) {
      // 1440 minutes in a day
      const days = Math.floor(diffMinutes / 1440);
      const hours = Math.floor((diffMinutes % 1440) / 60);
      return `${days} jours`;
    } else if (diffMinutes >= 60) {
      const hours = Math.floor(diffMinutes / 60);
      const minutes = diffMinutes % 60;
      return `${hours} h ${minutes.toString().padStart(2, "0")} min`;
    } else {
      return `${diffMinutes} min`;
    }
  };

  const toggleheatmap = (event) => {
    if (showlayer !== "heatmap") {
      setShowLayer("heatmap");
    } else {
      setShowLayer("points");
    }
  };

  const togglePopup = (event) => {
    props.setParametres_json((prevState) => ({
      ...prevState,
      showPopup: !prevState.showPopup,
    }));
  };

  const toggleThemePopup = (event) => {
    // Toggle the themePopup state
    props.setParametres_json((prevState) => ({
      ...prevState,
      themePopup: !prevState.themePopup,
    }));
    // console.log(props.parametres_json.themePopup, "THEME POPUP TRIGER");
  };

  //Lorsque l'on change le theme de la popup, il faut accéder au css de mapbox, et il faut aussi traquer les nouvelles popups pour que le style leur soit aussi appliqué
  const handleStyleChanges = () => {
    const popupContentElements = document.getElementsByClassName("mapboxgl-popup-content");
    // const popupTipElements = document.getElementsByClassName("mapboxgl-popup-tip");

    if (props.parametres_json.themePopup) {
      for (let element of popupContentElements) {
        element.style.background = "#514f52";
        element.style.color = "white";
      }
      // for (let element of popupTipElements) {
      //   element.style.borderBottomColor = "#514f52";
      //   element.style.borderTopColor = "#514f52";
      //   element.style.borderRightColor = "#514f52";
      //   element.style.borderLeftColor = "#514f52";
      // }
      setPopupStyle((prev) => ({
        ...prev,
        color: "white", // updating the color property to white
      }));
    } else {
      for (let element of popupContentElements) {
        element.style.background = "white";
        element.style.color = "black";
      }
      // for (let element of popupTipElements) {
      //   element.style.borderBottomColor = "";
      //   element.style.borderTopColor = "";
      //   element.style.borderRightColor = "";
      //   element.style.borderLeftColor = "";
      // }
      setPopupStyle((prev) => ({
        ...prev,
        color: "", // updating the color property to white
      }));
    }
  };

  useEffect(() => {
    handleStyleChanges();

  }, [props.parametres_json.themePopup, props.parametres_json.showPopup, props.parametres_json.minscore_popup, memorizedsource, props.geoJson,props.parametres_json.maxscore_popup,props.irregularitygeoJson,props.tipigeoJson]);

  useEffect(() => {
    setPopupStyle((prev) => ({
      ...prev,
      fontSize: `${props.parametres_json.descriptifSize}vh`,
      lineHeight: `${props.parametres_json.descriptifSize * 1.25}vh`,
    }));
  
    setCircleStyle( {height: `${props.parametres_json.descriptifSize * 1.75}vh`,
    width: `${props.parametres_json.descriptifSize * 1.75}vh`,})
  }, [props.parametres_json.descriptifSize]);

  const valuetext = (value) => {
    return `${value}`;
  };

  const handleToggleParametres = () => {
    setShowParametres(!showParametres);
  };

  //les images se chargent trop souvent, fonction à revoir
  const handleStyleLoad = (event) => {
    const map = event.target;
    map.getCanvas().style.cursor = "default";

    // Handle missing images
    map.on("styleimagemissing", (event) => {
      const id = event.id;
      const loadImageAndAdd = (imageSrc) => {
        map.loadImage(imageSrc, (error, image) => {
          if (error) throw error;
          map.addImage(id, image);
        });
      };

      switch (id) {
        case "Danger_round":
          loadImageAndAdd(Danger_round);
          break;
        case "route_barree_round":
          loadImageAndAdd(route_barree_round);
          break;
        case "Accident_round":
          loadImageAndAdd(Accident_round);
          break;
        case "embouteillage_round":
          loadImageAndAdd(embouteillage_round);
          break;
        case "neige_round":
          loadImageAndAdd(neige_round);
          break;
        case "obstacle_round":
          loadImageAndAdd(obstacle_round);
          break;
        case "feu_round":
          loadImageAndAdd(feu_round);
          break;
        case "voiture_arretee_round":
          loadImageAndAdd(voiture_arretee_round);
          break;
        case "chantier_round":
          loadImageAndAdd(chantier_round);
          break;
        case "meteo_round":
          loadImageAndAdd(meteo_round);
          break;
        default:
          // console.log("Unknown icon:", id);
      }
    });

    //faire en sorte que les lignes d'embouteillages apparaissent en dessous des icones
    map.on("data", (e) => {
      if (e.dataType === "source" && e.sourceId === "composite") {
        if (map.getLayer("all-reports-icons")) {
          map.moveLayer("all-reports-icons");
        }
        if (map.getLayer("all-reports")) {
          map.moveLayer("all-reports", "all-reports-icons");
        }
        if (map.getLayer("all-reports-heatmap")) {
          map.moveLayer("all-reports-heatmap", "all-reports");
        }
        
        
      }
    }
  );
  };

  return (
    <div>
      <div className="grande-carte-div" style={grandeCarteStyle}>
        <ReactMapGL
          onLoad={handleStyleLoad}
          {...props.viewport}
          mapStyle={mapboxStyles[mapboxStyleIndex]}
          mapboxAccessToken="pk.eyJ1IjoiY2hhaDYwNDg4IiwiYSI6ImNsc2E3ZmR2bjAwOHUybG81dzE0c24ybnYifQ.APsYLkryQsu19baQz4y0bg" // Utilisez votre token Mapbox ici
          ref={props.mapRef}
          interactiveLayerIds={[
            "all-reports",
            "line-layer",
            "line-layer2",
            "polygon-layer",
            "tipi",
          ]} // cette ligne permet de créer e.features lorsque la souris passe par dessus d'un layer avec id="data"
          onViewportChange={(nextViewport) => props.setViewport(nextViewport)}
          onMouseLeave={handleMouseLeave}
          onClick={handleMapClick}
          className="Map2"
          onMove={(evt) => {
            props.setViewport(evt.viewport);
          }}
          onMouseMove={(e) => {
            if (e.features && e.features.length > 0) {
              // On verifie que la souris est bien au dessus d'un layer (un élément de la carte)

              const feature = e.features[0] && e.features;
              //console.log(feature);
              //La ligne précédente permet d'accéder a toutes les variables du layer sur lequel passe la souris
              //ici le layer représente une alerte, on peut donc accéder a ses coordonées sur la carte ect
              if (feature[0].source == "points-data") {
                setHoverInfo({
                  source: e.features[0].source,
                  longitude: e.lngLat.lng,
                  latitude: e.lngLat.lat,
                  reportsubtype: e.features[0].properties.reportsubtype,
                  pubmillis: minutesAgo(e.features[0].properties.pubMillis),
                  reporttype: e.features[0].properties.reporttype,
                  score: e.features[0].properties.score,
                  lib_unite: e.features[0].properties.lib_unite,
                  uuid: e.features[0].properties.uuid,
                });
              }
              if (feature[0].source == "tipi") {
                setHoverInfo({
                  source: e.features[0].source,
                  longitude: e.lngLat.lng,
                  latitude: e.lngLat.lat,
                  reportsubtype: e.features[0].properties.type,
                  pubmillis: minutesAgo(e.features[0].properties.publisheddate),
                  road: e.features[0].properties.score,
                  score: e.features[0].properties.score,
                });
              }
              if (feature[0].source == "line-data2") {
                setHoverInfo({
                  source: e.features[0].source,
                  longitude: e.lngLat.lng,
                  latitude: e.lngLat.lat,
                  delay: e.features[0].properties.delayseconds,
                  pubmillis: minutesAgo(
                    e.features[0].properties.detectionDateMillis
                  ),
                  length: e.features[0].properties.length,
                  score: e.features[0].properties.score,
                });
              }
              if (feature[0].source == "line-data") {
                setHoverInfo({
                  source: e.features[0].source,
                  longitude: e.lngLat.lng,
                  latitude: e.lngLat.lat,

                  pubmillis: minutesAgo(e.features[0].properties.pubmillis),
                  length: e.features[0].properties.length,
                  score: e.features[0].properties.score,
                  speed: e.features[0].properties.speed,
                  level: e.features[0].properties.level,
                });
                // console.log(hoverInfo);
              }
            }
          }}
        >
          {props.tipigeoJson && (
            <Source
              id="tipi"
              type="geojson"
              data={props.tipigeoJson}
              promoteId="id"
              generateId={true}
              // key={JSON.stringify(props.tipigeoJson)}
            >
              <Layer
                id="tipi"
                type="symbol"
                layout={{
                  "icon-image": "Accident_round",
                  "icon-size": [
                    "*",
                    [
                      "case",
                      ["==", ["get", "uuid"], ["literal", props.clickdata]],
                      1.5,
                      1
                    ],
                    props.parametres_json.iconSize
                  ],
                }}
                filter={["all", ["==", ["get", "isinfeed"], true]]}
              />
            </Source>
          )}

          {props.departmentgeoJson && (
            <Source
              id="department-data"
              type="geojson"
              data={props.departmentgeoJson}
              promoteId="id"
              generateId={true}
            >
              <Layer
                id="department-layer"
                type="fill"
                paint={{
                  "fill-color": [
                    "case",
                    ["==", ["get", "code"], props.department],
                    "rgba(0, 0, 0, 0)",
                    "white", // Transparent color for other features
                  ],
                  "fill-opacity": 0.2,
                }}
                layout={{
                  visibility:
                    showlayer === "heatmap" || showlayer === "points"
                      ? "visible"
                      : "none",
                }}
              />
              {/* <Layer
    id="stencil-layer"
    type="fill"
    paint={{
      "fill-color": "blue",
      "fill-opacity": 0.5
    }}
    
    
  />  */}
            </Source>
          )}

          {props.geoJson && (
            <Source
              id="points-data"
              type="geojson"
              data={props.geoJson}
              promoteId="uuid"
              generateId={true}
              // key={JSON.stringify(props.geoJson)}
            >
              <Layer
                id="all-reports"
                type="circle"
                paint={{
                  "circle-color": [
                    "interpolate",
                    ["linear"],
                    ["get", "score"],
                    0,
                    "blue",
                    1,
                    "orange",
                    7,
                    "red",
                    10,
                    "red",
                  ],
                  "circle-radius": ["*", 3, props.parametres_json.alertSize],
                  "circle-stroke-color": "white",
                  "circle-stroke-width": [
                    "*",
                    2,
                    props.parametres_json.alertSize,
                  ],
                  ...(props.selectedFeature && {
                    "circle-stroke-color": [
                      "case",
                      ["==", ["get", "uuid"], props.selectedFeature],
                      "black",
                      "white",
                    ],
                  }),
                }}
                layout={{
                  visibility:
                    showlayer === "heatmap" || showlayer === "points"
                      ? "visible"
                      : "none",
                }}
                filter={[
                  "all",
                  ["!=", ["get", "reporttype"], "Embouteillage"],
                ]}
              />
              <Layer
                id="all-reports-heatmap"
                type="heatmap"
                paint={{
                  "heatmap-weight": [
                    "interpolate",
                    ["linear"],
                    ["get", "score"],
                    0,
                    0.01,
                    1,
                    0.1,
                    2,
                    0.1,
                    3,
                    0.1,
                    4,
                    0.1,
                    5,
                    0.1,
                    6,
                    0.1,
                    7,
                    1,
                    8,
                    1,
                    9,
                    1,
                    10,
                    10,
                  ],
                  "heatmap-intensity": [
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    0,
                    2,
                    9,
                    2,
                  ],
                  "heatmap-color": [
                    "interpolate",
                    ["linear"],
                    ["heatmap-density"],
                    0,
                    "rgba(255,255,255,0)", // Transparent
                    0.2,
                    "rgb(252, 211, 77)", // jaune clair
                    0.4,
                    "rgb(251,191, 36)", //  jaune moyen
                    0.6,
                    "rgb(245, 158, 11)", // orange
                    0.8,
                    "rgb(255,69,0)", // Rouge-orangé
                    1,
                    "rgb(178,24,43)",
                  ],
                  "heatmap-radius": [
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    0,
                    2 * props.parametres_json.heatmapSize,
                    9,
                    40 * props.parametres_json.heatmapSize,
                  ],
                  "heatmap-opacity": [
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    9,
                    1,
                    12,
                    0.5,
                  ],
                }}
                layout={{
                  visibility: showlayer === "heatmap" ? "visible" : "none",
                }}
              />

              <Layer
                id="all-reports-icons"
                type="symbol"
                layout={{
                  "icon-image": [
                    "coalesce",
                    [
                      "match",
                      ["get", "reportsubtype"],
                      "Vague de chaleur",
                      "feu_round",
                      "Travaux - route",
                      "chantier_round",
                      "Route fermée pour travaux",
                      "chantier_round",
                      "HAZARD_WEATHER",
                      "meteo_round",
                      "Brouillard",
                      "meteo_round",
                      "Pluie forte",
                      "meteo_round",
                      "Inondation",
                      "meteo_round",
                      "Mousson",
                      "meteo_round",
                      "Tornade",
                      "meteo_round",
                      "Ouragan",
                      "meteo_round",
                      "Pluie verglaçante",
                      "meteo_round",
                      "Neige abondante",
                      "neige_round",
                      "Grêle",
                      "neige_round",
                      "Objet - route",
                      "obstacle_round",
                      "Véhicule arrêté bas-côté",
                      "voiture_arretee_round",
                      "Véhicule arrêté - route",
                      "voiture_arretee_round",
                      "", // Add a default icon here
                    ],
                    [
                      "match",
                      ["get", "reporttype"],
                      "Danger",
                      "Danger_round",
                      "Accident",
                      "Accident_round",
                      "Route fermée",
                      "route_barree_round",
                      "Embouteillage",
                      "",
                      "",
                    ],
                  ],
                  "icon-size": [
                    "*",
                    [
                      "case",
                      ["==", ["get", "uuid"], ["literal", props.clickdata]],
                      1.5,
                      1
                    ],
                    props.parametres_json.iconSize
                  ], //charles
                  "symbol-sort-key": [
                    "*",
                    [
                      "case",
                      ["==", ["get", "uuid"], ["literal", props.clickdata]],
                      1,
                      1.5
                    ],
                    props.parametres_json.iconSize,
                    -1
                  ],
                  "icon-allow-overlap": true,
                  
                }}
                paint={{
                  "icon-opacity": [
                    "case",
                    [
                      "all",
                      ["==", ["get", "isinfeed"], true],
                      [">", ["get", "pubmillis_unix"], fourMinutesAgo],
                    ],
                    opacity,
                    1, //default
                  ],
                }}
                filter={["all", ["==", ["get", "isinfeed"], true]]}
              />
            </Source>
          )}

{props.jamgeoJson && (
            <Source
              id="line-data"
              type="geojson"
              data={props.jamgeoJson}
              promoteId="uuid"
              generateId={true}
              // key={JSON.stringify(props.jamgeoJson)}
            >
             
                <Layer
                  id="line-layer"
                  type="line"
                  paint={{
                    "line-color": [
                      "case",
                      ["==", ["get", "level"], 1],
                      "yellow",
                      ["==", ["get", "level"], 2],
                      "yellow",
                      ["==", ["get", "level"], 3],
                      "orange",
                      ["==", ["get", "level"], 4],
                      "orange",
                      ["==", ["get", "level"], 5],
                      "white",
                      "yellow",
                    ],

                    "line-width": ["*", 2, props.parametres_json.lignSize],
                    "line-dasharray": [
                      "case",
                      ["==", ["get", "level"], 5],
                      [2, 2], // Dash pattern for level 5
                      dashArray, // Continuous line for other levels
                    ],
                  }}
                  filter={[
                    "all",
                    ["==", ["get", "isinfeed"], true],
                    ["==", ["get", "isirregularity"], false]
                  ]}
                />
              

                <Layer
                  id="line-layer-low-opacity"
                  type="line"
                  paint={{
                    "line-color": [
                      "case",
                      ["==", ["get", "level"], 1],
                      "yellow",
                      ["==", ["get", "level"], 2],
                      "yellow",
                      ["==", ["get", "level"], 3],
                      "orange",
                      ["==", ["get", "level"], 4],
                      "orange",
                      ["==", ["get", "level"], 5],
                      "white",
                      "yellow",
                    ],

                    "line-width": ["*", 2, props.parametres_json.lignSize],
                    "line-opacity": 0.4,
                  }}
                  filter={[
                    "all",
                    ["==", ["get", "isinfeed"], true],
                    ["!=", ["get", "level"], 5],
                  ]}
                />
              
            </Source>
          )}

          {props.irregularitygeoJson && (
            <Source
              id="line-data2"
              type="geojson"
              data={props.irregularitygeoJson}
              promoteId="uuid"
              generateId={true}
              // key={JSON.stringify(props.irregularitygeoJson)}
            >
            
                <Layer
                  id="line-layer2"
                  type="line"
                  paint={{
                    "line-color": "red",

                    "line-width": ["*", 2, props.parametres_json.lignSize],
                    "line-opacity": 0.4,
                  }}
                 
                  filter={["==", ["get", "isinfeed"], true]}
                />

                
             


                <Layer
                  id="line-layer2-low-opacity"
                  type="line"
                  paint={{
                    "line-color": "red",

                    "line-width": ["*", 2, props.parametres_json.lignSize],
                    "line-dasharray": [
                      "case",
                      ["==", ["get", "level"], 5],
                      [2, 2], // Dash pattern for level 5
                      dashArray, // Continuous line for other levels
                    ],
                  }}
                 
                  filter={["==", ["get", "isinfeed"], true]}
                />

                
    
            </Source>
          )}

          <Source
            id="polygon-data"
            type="geojson"
            data={props.brigadesgeoJson}
            promoteId="id"
          >
            <Layer
              id="brigades-layer"
              type="line"
              paint={{
                "line-color": "white",
                "line-opacity": 1,
                "line-width": 1,
              }}
              filter={["==", ["get", "competence"], "GN"]}
            />
            {showpolice && (
              <Layer
                id="police-layer"
                type="fill"
                paint={{
                  "fill-color": "white",
                  "fill-opacity": 0.2,
                }}
                filter={["!=", ["get", "competence"], "GN"]}
              />
            )}
          </Source>

          {hoverInfo && memorizedsource == "points-data" && (
            <Popup
              longitude={memorizedlongitude}
              latitude={memorizedlatitude}
              offsetTop={[0, -10]}
              closeButton={false}
              closeOnClick={true}
              className=" mapboxgl-popup"
            >
              <div
                className={`mapboxgl-popup-div mapboxgl-popup-div-${memorizedtype}`}
              >
                <div className="popup-icon">
                  {props.imageMap.reportsubtype[memorizedsubtype] && (
                    <img
                      src={props.imageMap.reportsubtype[memorizedsubtype]}
                      alt="test_round"
                      className="icon-popup no-cursor"
                      style={imageStyle}
                    />
                  )}
                  {!props.imageMap.reportsubtype[memorizedsubtype] && (
                    <img
                      src={props.imageMap.reporttype[memorizedReporttype]}
                      alt="test2_round"
                      className="icon-popup no-cursor"
                      style={imageStyle}
                    />
                  )}
                </div>
                <div className="popup-icon popup-icon1">
                  <h3 style={PopupStyle}>{memorizedReporttype}</h3>
                  <h4 style={PopupStyle}>il y a {memorizedpubmillis} </h4>
                </div>
                {memorizedscore >= 7 && (
                  <div className="feu-tricolore-popup w-5 h-5  bg-red-500 rounded-full" style={circleStyle} ></div>
                )}
                {memorizedscore >= 1 && memorizedscore < 7 && (
                  <div className="feu-tricolore-popup w-5 h-5  bg-orange-500 rounded-full" style={circleStyle} ></div>
                )}
                {memorizedscore < 1 && (
                  <div className="feu-tricolore-popup w-5 h-5  bg-sky-600 rounded-full" style={circleStyle} ></div>
                )}
              </div>

              <h4 style={PopupStyle}>
                {(memorizedReporttype === "Danger" ||
                  memorizedReporttype === "Embouteillage") &&
                  memorizedsubtype}
              </h4>
            </Popup>
          )}

          {hoverInfo && memorizedsource == "line-data" && (
            <Popup
              longitude={memorizedlongitude}
              latitude={memorizedlatitude}
              offsetTop={[0, -10]}
              closeButton={false}
              closeOnClick={false}
              className="mapboxgl-popup"
            >
              <div
                className={`mapboxgl-popup-div mapboxgl-popup-div-Embouteillage`}
              >
                <div className="popup-icon">
                  <img
                    src={props.imageMap.reporttype["Embouteillage"]}
                    alt="accident_round"
                    className="icon-popup no-cursor"
                    style={imageStyle}
                  />
                </div>
                <div className="popup-icon popup-icon1">
                  <h3 style={PopupStyle}>
                    {" "}
                    {memorizedlevel === 5 ? "Route fermée" : "Embouteillage"}
                  </h3>
                  <h4 style={PopupStyle}>il y a {memorizedpubmillis} </h4>
                </div>
              </div>

              <h4 style={PopupStyle}>Longeur : {memorizedlength} m</h4>
              <h4 style={PopupStyle}>
                Vitesse : {memorizedspeed.toFixed(1)} km/h
              </h4>
            </Popup>
          )}

          {props.geoJson &&
            props.parametres_json.showPopup &&
            props.geoJson.features.map((feature, index) => {
              // Add index as the second parameter

              if (
                feature.properties.score >=
                  props.parametres_json.minscore_popup &&
                feature.properties.score <=
                  props.parametres_json.maxscore_popup &&
                feature.properties.reporttype !== "Embouteillage" &&
                feature.properties.isinfeed === true
              ) {
                
                return (
                  <Popup
                    key={index} // Use the index as the key
                    longitude={feature.geometry.coordinates[0]}
                    latitude={feature.geometry.coordinates[1]}
                    offsetTop={[0, 10]}
                    closeButton={false}
                    closeOnClick={false}
                    // anchor="top"
                    className="mapboxgl-popup"
                  >
                    <div
                      className={`mapboxgl-popup-div mapboxgl-popup-div-${feature.properties.reporttype}`}
                    >
                      <div className="popup-icon">
                        {props.imageMap.reportsubtype[
                          feature.properties.reportsubtype
                        ] && (
                          <img
                            src={
                              props.imageMap.reportsubtype[
                                feature.properties.reportsubtype
                              ]
                            }
                            alt="test_round"
                            className="icon-popup no-cursor"
                            style={imageStyle}
                          />
                        )}
                        {!props.imageMap.reportsubtype[
                          feature.properties.reportsubtype
                        ] && (
                          <img
                            src={
                              props.imageMap.reporttype[
                                feature.properties.reporttype
                              ]
                            }
                            alt="test2_round"
                            className="icon-popup no-cursor"
                            style={imageStyle}
                          />
                        )}
                      </div>

                      <div className="popup-icon popup-icon1">
                        <h3 style={PopupStyle}>
                          {feature.properties.reporttype}
                        </h3>
                        <h4 style={PopupStyle}>
                          il y a {minutesAgo(feature.properties.pubMillis)}
                        </h4>
                      </div>
                      {feature.properties.score >= 7 && (
                  <div className="feu-tricolore-popup w-5 h-5  bg-red-500 rounded-full" style={circleStyle}></div>
                )}
                {feature.properties.score >= 1 && feature.properties.score < 7 && (
                  <div className="feu-tricolore-popup w-5 h-5  bg-orange-500 rounded-full" style={circleStyle}></div>
                )}
                {feature.properties.score < 1 && (
                  <div className="feu-tricolore-popup w-5 h-5  bg-sky-600 rounded-full" style={circleStyle}></div>
                )}
                    </div>
                    <h4 style={PopupStyle}>
                      {feature.properties.reporttype === "Danger" &&
                        feature.properties.reportsubtype}
                    </h4>
                  </Popup>
                );
                
              } else {
                return null; // Don't render the Popup if score is not greater than 0
              }
            
            })}
              

          {props.irregularitygeoJson &&
            props.parametres_json.showPopup &&
            props.irregularitygeoJson.features.map((feature, index) => {
              // Add index as the second parameter
              //console.log(feature.properties)
              if (
                feature.properties.score >=
                  props.parametres_json.minscore_popup &&
                feature.properties.score <=
                  props.parametres_json.maxscore_popup &&
                feature.properties.isinfeed === true
              ) {
                return (
                  <Popup
                    key={index} // Use the index as the key
                    longitude={feature.geometry.coordinates[0][0]}
                    latitude={feature.geometry.coordinates[0][1]}
                    offsetTop={[0, -10]}
                    closeButton={false}
                    closeOnClick={false}
                    className="mapboxgl-popup"
                  >
                    <div
                      className={`mapboxgl-popup-div mapboxgl-popup-div-Embouteillage`}
                    >
                      <div className="popup-icon">
                        <img
                          src={props.imageMap.reporttype["Embouteillage"]}
                          alt="accident_round"
                          className="icon-popup no-cursor"
                          style={imageStyle}
                        />
                      </div>
                      <div className="popup-icon popup-icon1">
                        <h3 style={PopupStyle}>Bouchon anormal</h3>
                        <h4 style={PopupStyle}>
                          il y a{" "}
                          {minutesAgo(feature.properties.detectionDateMillis)}{" "}
                        </h4>
                      </div>
                    </div>

                    <h4 style={PopupStyle}>
                      Longeur : {feature.properties.length} m
                    </h4>
                    <h4 style={PopupStyle}>
                      Ralentissement : {Math.floor(feature.properties.delayseconds / 60)}{" "}
                      minutes
                    </h4>
                    <h4 style={PopupStyle}>
                      Vitesse : {feature.properties.speed} km/h
                    </h4>
                  </Popup>
                );
              } else {
                return null; // Don't render the Popup if score is not greater than 0
              }
            })}

          {props.tipigeoJson &&
            props.parametres_json.showPopup &&
            props.tipigeoJson.features.map((feature, index) => {
              // Add index as the second parameter
              if (
                // feature.properties.score > 3
                // &&
                feature.properties.isinfeed === true
              ) {
                return (
                  <Popup
                    key={index} // Use the index as the key
                    longitude={feature.geometry.coordinates[0]}
                    latitude={feature.geometry.coordinates[1]}
                    offsetTop={[0, -10]}
                    closeButton={false}
                    closeOnClick={false}
                    className="mapboxgl-popup"
                    style={imageStyle}
                  >
                    <div
                      className={`mapboxgl-popup-div mapboxgl-popup-div-Accident`}
                    >
                      <div className="popup-icon">
                        <img
                          src={props.imageMap.reporttype["Accident"]}
                          alt="test_round"
                          className="icon-popup no-cursor"
                        />
                      </div>

                      <div className="popup-icon popup-icon1">
                        <h3 style={PopupStyle}>Accident - tipi</h3>
                        <h4 style={PopupStyle}>
                          il y a {minutesAgo(feature.properties.date)}
                        </h4>
                      </div>
                      {feature.properties.score >= 7 && (
                  <div className="feu-tricolore-popup w-5 h-5  bg-red-500 rounded-full" style={circleStyle}></div>
                )}
                {feature.properties.score >= 1 && feature.properties.score < 7 && (
                  <div className="feu-tricolore-popup w-5 h-5  bg-orange-500 rounded-full" style={circleStyle}></div>
                )}
                {feature.properties.score < 1 && (
                  <div className="feu-tricolore-popup w-5 h-5  bg-sky-600 rounded-full" style={circleStyle}></div>
                )}
                    </div>
                  </Popup>
                );
              } else {
                return null; // Don't render the Popup if score is not greater than 0
              }
            })}

          {props.department && proximity1 && (
            <Geocoder department={props.department} proximity1={proximity1} />
          )}
        </ReactMapGL>

        <div>
          <div className="grande-carte-container-toggle ">
            <div className="grande-carte-buttons">
              <div className="grande-carte-button-container">
                <img
                  src={Parametres_icon}
                  alt="close"
                  className="icon-filtre cursor"
                  on
                  onClick={handleToggleParametres}
                />
              </div>

              <div className="grande-carte-button-container">
                <p className="grande-carte-toggle-buttons-labels">Heatmap</p>
                <label
                  className="flex items-center space-x-2 "
                  // onMouseEnter={Heatmap_popup_on}
                  // onMouseLeave={Heatmap_popup_off}
                >
                  <div className="relative">
                    <input
                      onClick={toggleheatmap}
                      type="checkbox"
                      value=""
                      className="sr-only peer "
                      id="heatmap-checkbox"
                    />
                    <div className="cursor map-button relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </div>
                  <div className="relative z-index"></div>
                </label>
              </div>
              <div className="grande-carte-button-container">
                <p className="grande-carte-toggle-buttons-labels">Police</p>
                <label
                  className="flex items-center space-x-2 "
                  // onMouseEnter={Heatmap_popup_on}
                  // onMouseLeave={Heatmap_popup_off}
                >
                  <div className="relative">
                    <input
                      onClick={() => {
                        setShowPolice(!showpolice);
                      }}
                      type="checkbox"
                      value=""
                      className="sr-only peer "
                      id="police-checkbox"
                    />
                    <div className="cursor map-button relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </div>
                  <div className="relative z-index"></div>
                </label>
              </div>
              <div className="relative style-carte-container">
                <button
                  id="dropdownDefaultButton"
                  data-dropdown-toggle="dropdown"
                  className="text-stylecarte  rounded-lg   text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  type="button"
                >
                  Style de carte
                  <svg
                    className="w-2.5 h-2.5 ms-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 5 4-4 4 4"
                    />
                  </svg>
                </button>

                <div
                  id="dropdown"
                  className="style-de-carte z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 absolute dropup-menu"
                >
                  <ul
                    className="py-2 text-sm text-gray-700 dark:text-gray-200"
                    aria-labelledby="dropdownDefaultButton"
                  >
                    <li>
                      <a
                        href="#"
                        className="text-stylecarte block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        onClick={() => {
                          setstyleCarte("Theme blanc");
                        }}
                      >
                        Theme blanc
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="text-stylecarte block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        onClick={() => {
                          setstyleCarte("Satellite street");
                        }}
                      >
                        Satellite street
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="text-stylecarte block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        onClick={() => {
                          setstyleCarte("Theme noir");
                        }}
                      >
                        Theme noir
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="text-stylecarte block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        onClick={() => {
                          setstyleCarte("Theme navigation");
                        }}
                      >
                        Theme navigation
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="text-stylecarte block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        onClick={() => {
                          setstyleCarte("Satellite");
                        }}
                      >
                        Satellite
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="text-stylecarte block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        onClick={() => {
                          setstyleCarte("Theme vert");
                        }}
                      >
                        Theme vert
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="button-additionnels-container">
            <img
              src={reset}
              alt="reset"
              className="icon-close-parametres cursor "
              onClick={() =>
                props.mapRef.current.flyTo({
                  center: [
                    props.initialviewport.longitude,
                    props.initialviewport.latitude,
                  ],
                  zoom: 9,
                })
              }
            />
            <img
              src={zoom}
              alt="zoom"
              className="icon-close-parametres cursor "
              onClick={() => props.mapRef.current.zoomIn()}
            />
            <img
              src={unzoom}
              alt="unzoom"
              className="icon-close-parametres cursor "
              onClick={() => props.mapRef.current.zoomOut()}
            />
          </div>
          <Slide in={showParametres} direction="up" mountOnEnter unmountOnExit>
            <div>
              <div className="grande-carte-parametres-container">
                <div className="parametres-container-header">
                  <div>
                    <img
                      src={icon_close}
                      alt="close"
                      className="icon-close-parametres cursor "
                      onClick={handleToggleParametres}
                    />
                  </div>
                  <div>
                    <img
                      src={sauvegarder}
                      alt="close"
                      className="icon-sauvegarder-parametres cursor"
                      onClick={props.SaveFilter}
                    />
                  </div>
                </div>
                <p className="grande-carte-toggle-buttons-labels symetrie">
                  Theme descriptif
                </p>
                <label className=" space-x-2 descriptif-toggle ">
                  <div className="relative">
                    <input
                      onClick={toggleThemePopup}
                      type="checkbox"
                      checked={props.parametres_json.themePopup}
                      value={props.parametres_json.themePopup}
                      className="sr-only peer"
                      id="popup-checkbox"
                    />
                    <div className="map-button cursor relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-stone-700  rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-stone-700"></div>
                  </div>
                  <div className="relative z-index"></div>
                </label>
                <p className="grande-carte-toggle-buttons-labels symetrie">
                  Descriptif
                </p>
                <label className=" space-x-2 descriptif-toggle ">
                  <div className="relative">
                    <input
                      onClick={togglePopup}
                      type="checkbox"
                      checked={props.parametres_json.showPopup}
                      value={props.parametres_json.showPopup}
                      className="sr-only peer"
                      id="popup-checkbox"
                    />
                    <div className="map-button cursor relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-stone-700  rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-stone-700"></div>
                  </div>
                  <div className="relative z-index"></div>
                </label>

                <p className="icon-size-text">Score descriptif</p>
                <div className="icon-size-container">
                  <Jauge_score_popup
                    setFilter={props.setParametres_json}
                    filter={props.parametres_json}
                  />
                </div>

                <p className="icon-size-text">Taille du fil d'actualité</p>
                <div className="icon-size-container">
                  <Slider
                    aria-label="Small steps"
                    defaultValue={props.parametres_json.filactuSize}
                    getAriaValueText={valuetext}
                    step={0.25}
                    onChange={(event, newValue) => {
                      setTimeout(() => {
                        props.setParametres_json((prev) => ({
                          ...prev,
                          filactuSize: newValue,
                        }));
                      }, 1000);
                    }}
                    marks
                    min={0.75}
                    max={1.75}
                    valueLabelDisplay="auto"
                    size="small"
                  />
                </div>

                <p className="icon-size-text">Taille du descriptif</p>
                <div className="icon-size-container">
                  <Slider
                    aria-label="Small steps"
                    defaultValue={props.parametres_json.descriptifSize}
                    getAriaValueText={valuetext}
                    step={0.25}
                    onChange={(event, newValue) => {
                     
                        props.setParametres_json((prev) => ({
                          ...prev,
                          descriptifSize: newValue,
                        }));
                      
                    }}
                    marks
                    min={0.75}
                    max={2}
                    valueLabelDisplay="auto"
                    size="small"
                  />
                </div>

                <p className="icon-size-text">Taille des icones</p>
                <div className="icon-size-container">
                  <Slider
                    aria-label="Small steps"
                    defaultValue={props.parametres_json.iconSize}
                    getAriaValueText={valuetext}
                    step={0.2}
                    onChange={(event, newValue) =>
                      props.setParametres_json((prev) => ({
                        ...prev,
                        iconSize: newValue,
                      }))
                    }
                    marks
                    min={0.2}
                    max={1}
                    valueLabelDisplay="auto"
                    size="small"
                  />
                </div>

                <p className="icon-size-text">Taille des lignes de bouchons</p>
                <div className="icon-size-container">
                  <Slider
                    aria-label="Small steps"
                    defaultValue={props.parametres_json.lignSize}
                    getAriaValueText={valuetext}
                    step={1.25}
                    onChange={(event, newValue) =>
                      props.setParametres_json((prev) => ({
                        ...prev,
                        lignSize: newValue,
                      }))
                    }
                    marks
                    min={0}
                    max={5}
                    valueLabelDisplay="auto"
                    size="small"
                  />
                </div>

                <p className="icon-size-text">Taille des points</p>
                <div className="icon-size-container">
                  <Slider
                    aria-label="Small steps"
                    defaultValue={props.parametres_json.alertSize}
                    getAriaValueText={valuetext}
                    step={0.5}
                    onChange={(event, newValue) =>
                      props.setParametres_json((prev) => ({
                        ...prev,
                        alertSize: newValue,
                      }))
                    }
                    marks
                    min={0.5}
                    max={3.0}
                    valueLabelDisplay="auto"
                    size="small"
                  />
                </div>

                <p className="icon-size-text">Réglage heatmap</p>
                <div className="icon-size-container">
                  <Slider
                    aria-label="Small steps"
                    defaultValue={props.parametres_json.heatmapSize}
                    getAriaValueText={valuetext}
                    step={1}
                    onChange={(event, newValue) =>
                      props.setParametres_json((prev) => ({
                        ...prev,
                        heatmapSize: newValue,
                      }))
                    }
                    marks
                    min={1}
                    max={4}
                    valueLabelDisplay="auto"
                    size="small"
                  />
                </div>
              </div>
            </div>
          </Slide>
        </div>
      </div>
    </div>
  );
};

export default Grande_carte;
