import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Login(props) {
  const navigate = useNavigate();
  const [loginForm, setLoginForm] = useState({
    username: "",
    password: ""
  });
  const [errorMessage, setErrorMessage] = useState("");

  function logMeIn(event) {
    event.preventDefault();

    // Validation check
    if (!loginForm.username || !loginForm.password) {
      setErrorMessage('Identifiant et mot de passe sont requis.');
      return; // Stop the function from proceeding
    }

    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BACK_URL}/token`,
      data: {
        username: loginForm.username,
        password: loginForm.password
      }
    })
      .then((response) => {
        props.setToken(response.data.access_token);
        navigate('/visu_grande_carte');
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          console.log(error.response.status);
          console.log(error.response.headers);
          setErrorMessage('L\'identifiant ou le mot de passe est erronné.');
        }
      });

    setLoginForm({
      username: "",
      password: ""
    });
  }

  function handleChange(event) {
    const { value, name } = event.target;
    setLoginForm((prevNote) => ({
      ...prevNote,
      [name]: value
    }));
  }

  return (
    <div className="login-component">
      <div className="Home">
        <form action="/login" method="post">
        {errorMessage && (
                <div style={{ color: 'red', marginBottom: '10px' }}>
                  {errorMessage}
                </div>
              )}
          <Grid container spacing={2} justifyContent="center">
          
            
            
            <Grid item xs={12}>
              <TextField
                id="username"
                name="username"
                label="Identifiant"
                variant="outlined"
                onChange={handleChange}
                value={loginForm.username}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="password"
                name="password"
                label="Mot de passe"
                type="password"
                variant="outlined"
                onChange={handleChange}
                value={loginForm.password}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" className="button" onClick={logMeIn} style={{ marginBottom: 189 }}>
                Connexion
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
}

export default Login;
