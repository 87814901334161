import React from "react";
import { useEffect } from "react";


const AnomalyGrandeCarte = ({
  anomaly,
  index,
  setMapcoords,
  data,
  Updatedata,
  alerts,
  tipi,
  selectedFeature,
  setSelectedFeature,
  imageMap,
  imageStyle,
  circleStyle,
 
  
}) => {

  // useEffect(() => {
  //   console.log(selectedFeature,'selectedFeature')
  // }, [selectedFeature]);
  

  //Convertit un time stamp with time zone en mins
  const minutesAgo = (timestamp) => {
    const timestampDt = new Date(timestamp);
    const currentDt = new Date();
    const diffMs = currentDt - timestampDt;
    const diffMinutes = Math.floor(diffMs / (1000 * 60));
   
  
    if (diffMinutes >= 1440) { // 1440 minutes in a day
      const days = Math.floor(diffMinutes / 1440);
      const hours = Math.floor((diffMinutes % 1440) / 60);
      return `${days} jours`;
    } else if (diffMinutes >= 60) {
      const hours = Math.floor(diffMinutes / 60);
      const minutes = diffMinutes % 60;
      return `${hours} h ${minutes.toString().padStart(2, '0')} min`;
    } else {
      return `${diffMinutes} min`;
    }
  };

  const handleClick = (type) => {
  if (!type) {
    console.log("Data is not available yet.");
    return;
  }

  if (anomaly.uuid !== selectedFeature) {
    // Find the corresponding data with the same anomaly.uuid so that on click it changes component 2 infos
    const matchingData = Object.values(type).find(
      (item) => item.id === anomaly.uuid || item.uuid === anomaly.uuid
    );
    console.log("Matching Data:", typeof type, type);
    // Updatedata(anomaly.uuid);
    setSelectedFeature(anomaly.uuid);
    setMapcoords(anomaly.info.location);
  } else {
    // Updatedata(0)
    setSelectedFeature(-1)
    setMapcoords(-1);
  }
};

const handleMouseEnter = (type) => {
  if (!type) {
    console.log("Data is not available yet.");
    return;
  }

  if (anomaly.uuid !== selectedFeature) {
    // Find the corresponding data with the same anomaly.uuid so that on click it changes component 2 infos
    const matchingData = Object.values(type).find(
      (item) => item.id === anomaly.uuid || item.uuid === anomaly.uuid
    );
    // console.log("Matching Data:", typeof type, type);
    Updatedata(anomaly.uuid);
  } 
};

const handleMouseLeave = (type) => {
  if (!type) {
    console.log("Data is not available yet.");
    return;
  }
    Updatedata('abc');
 
};

  const handleClicklinkedalert = () => {
    if (anomaly.info.alertuuid) {
      // Trouver l'alerte correspondante à l'UUID de l'anomalie
      const matchingAlert = Object.values(alerts).find(
        (item) => item.uuid === anomaly.info.alertuuid
      );
  
      // Updatedata(matchingAlert);
      setSelectedFeature(anomaly.uuid);
      
  
    }
  };
  
  

  if (anomaly.info.category === 'accident' && anomaly.info.reportsubtype !=='tipi') {
    return (
      <>
        <div className="anomaly-container">
  {selectedFeature !== anomaly.uuid ? (
    <div
      key={index}
      className={`anomaly-detail transition-all duration-250 bg-stone-200 hover:bg-gray-700 focus:bg-white m-2 p-3 relative z-40 group flex justify-between`}
      onClick={() => handleClick(alerts)}
      onMouseEnter={() => handleMouseEnter(alerts)}
      onMouseLeave={() => handleMouseLeave(alerts)}
      tabIndex={0}
      
    >
      <div className="grande-carte-ir-items  group-hover:text-white group-focus:text-black">
        <img
          src={imageMap.reporttype['Accident']}
          alt="test_round"
          className="icon-popup no-cursor"
          style={imageStyle}
        />
      </div>
      <div>
        <div className="grande-carte-ir-items2 ir-items-blue-title bold group-hover:text-white group-focus:text-black">
          Accident
        </div>
        <div className="anomaly-time group-hover:text-white">
          {minutesAgo(anomaly.pubMillis)} 
        </div>
      </div>
      <div className="grande-carte-ir-items group-hover:text-white group-focus:text-black">
        <div className="feu-tricolore w-7 h-7 bg-red-500 rounded-full" style={circleStyle}></div>
      </div>
    </div>
  ) : (
    <div className="anomaly-detail bg-gray-700 transition-all duration-250 m-2">
      <div
        key={index}
        className="m-2 p-3 relative z-40 group flex justify-between"
        tabIndex={0}
        onClick={() => handleClick(alerts)}
        onMouseEnter={() => handleMouseEnter(alerts)}
        onMouseLeave={() => handleMouseLeave(alerts)}
        
      >
        <div className="grande-carte-ir-items-clicked">
          <img
            src={imageMap.reporttype['Accident']}
            alt="test_round"
            className="icon-popup no-cursor icon-popup-clicked"
            style={imageStyle}
          />
          <div className="anomaly-clicked-title">Accident</div>
        </div>
        
        <div className="grande-carte-ir-items-clicked">
          Il y a {minutesAgo(anomaly.pubMillis)} 
        </div>
      </div>
      <div className="anomaly-accident-description">
        {/* <div className="anomaly-clicked-text">Score :</div>
        <div className="jauge-container">
          <Jauge
            title="Score"
            className="grande-carte-popup-jauge"
            value={anomaly.info.score}
          />
        </div> */}
      </div>
    </div>
  )}
</div>

      </>
    );
  } else if (anomaly.info.category === 'embouteillage') { //IRREGULARITES, EMBOUTEILLAGE, 
    return (
      <>
        {selectedFeature !== anomaly.uuid ? (
          <div
            key={index}
            className={`anomaly-detail transition-all duration-250 bg-stone-200 hover:bg-gray-700 focus:bg-white m-2 p-3 relative z-40 group flex justify-between`}
            onClick={()=> handleClick(data)}
            tabIndex={0}
          >
            <div className="grande-carte-ir-items group-hover:text-white group-focus:text-black">
            <img
                           src={imageMap.reporttype['Embouteillage']}
                          alt="test_round"
                          className="icon-popup no-cursor"
                          style={imageStyle}
                        />
            </div>
            <div>
              <div className="grande-carte-ir-items2 ir-items-blue-title bold group-hover:text-white group-focus:text-black">
              {anomaly.info.reportsubtype ? anomaly.info.reportsubtype : 'Bouchon anormal'}
              </div>
              <div className="anomaly-time group-hover:text-white ">
                {minutesAgo(anomaly.pubMillis)} 
              </div>
            </div>
            <div className="grande-carte-ir-items group-hover:text-white group-focus:text-black">
              <div className="feu-tricolore w-7 h-7 bg-orange-400 rounded-full" style={circleStyle}></div>
            </div>
          </div>
        ) : (
          <div className="anomaly-detail bg-gray-700 transition-all duration-250 m-2">
            <div
              key={index}
              className="m-2 p-3 relative z-40 group flex justify-between"
              tabIndex={0}
              onClick={() => handleClick(alerts)}
            >
              <div className="grande-carte-ir-items-clicked">
              <img
                           src={imageMap.reporttype['Embouteillage']}
                          alt="test_round"
                          className="icon-popup no-cursor alert-inside-irregularities"
                          style={imageStyle}
                        />
                        <div className="anomaly-clicked-title">{anomaly.info.reportsubtype ? anomaly.info.reportsubtype : 'Bouchon anormal'}</div>
              </div>
              
              <div className="grande-carte-ir-items-clicked">
                Il y a {minutesAgo(anomaly.pubMillis)} 
              </div>
            </div>
            <div className="anomaly-accident-description">
  {anomaly.info.length && (
    <div className="anomaly-irregularities-container">
      <div className="anomaly-info-irregularities">
      {anomaly.info.length} m de longueur <br />
      {anomaly.info.speed} km / h <br/>
      {anomaly.info.street} 
      </div>
      
      <div>
      {anomaly.info.alertuuid && (() => {
        const LinkedAlert = Object.values(alerts).find(
          (item) => item.uuid === anomaly.info.alertuuid
        );
        if (LinkedAlert) {
          return (
            <div
          
              className="alert-detail-inside-irregularities transition-all duration-250 bg-stone-200 hover:bg-white focus:bg-white m-2 p-3 relative z-40 group flex justify-between"
              onClick={() => handleClicklinkedalert()}
           
            >
              <div className="alert-inside-irregularities-ir-items group-hover:text-black group-focus:text-black">
              {imageMap.reportsubtype[LinkedAlert.reportsubtype] ? (
              <img
                src={imageMap.reportsubtype[LinkedAlert.reportsubtype]}
                alt="test_round"
                className="icon-popup no-cursor alert-inside-irregularities"
                style={imageStyle}
              />
            ) : (
              <img
                src={imageMap.reporttype['Danger']}
                alt="test2_round"
                className="icon-popup no-cursor alert-inside-irregularities"
                style={imageStyle}
              />
            )}
                <div className=" ir-items-blue-title bold group-hover:text-black group-focus:text-black">
                  Cause : {LinkedAlert.reportsubtype}
                </div>
              </div>
              
            
            </div>
          );
        }
      })()}</div>

    </div>
  )}
</div>



          </div>
        )}
      </>
    );
  } else if (anomaly.info.category === 'danger' || anomaly.info.category==='intemperies' || anomaly.info.category==='information' ) { //DANGER
    return (
      <div className="anomaly-container">
      {selectedFeature !== anomaly.uuid ? (
        <div
          key={index}
          className={`anomaly-detail transition-all duration-250 bg-stone-200 hover:bg-gray-700 focus:bg-gray-700 m-2 p-3 relative z-40 group flex justify-between`}
          onClick={() => handleClick(alerts)}
          onMouseEnter={() => handleMouseEnter(alerts)}
          onMouseLeave={() => handleMouseLeave(alerts)}
          tabIndex={0}
        >
          <div className="grande-carte-ir-items group-hover:text-white group-focus:text-white">
            {imageMap.reportsubtype[anomaly.info.reportsubtype] ? (
              <img
                src={imageMap.reportsubtype[anomaly.info.reportsubtype]}
                alt="test_round"
                className="icon-popup no-cursor"
                style={imageStyle}
              />
            ) : (
              <img
                src={imageMap.reporttype['Danger']}
                alt="test2_round"
                className="icon-popup no-cursor"
                style={imageStyle}
              />
            )}
          </div>
          <div>
            <div className="grande-carte-ir-items2 ir-items-blue-title bold group-hover:text-white group-focus:text-white">
              {anomaly.info.reportsubtype}
            </div>
            <div className="anomaly-time group-hover:text-white group-focus:text-white">
              {minutesAgo(anomaly.pubMillis)} 
            </div>
          </div>
          <div className="grande-carte-ir-items group-hover:text-white group-focus:text-white">
            {anomaly.info.score >= 7 &&  (
              <div className="feu-tricolore w-7 h-7 bg-red-400 rounded-full " style={circleStyle}></div>
            )}
            {anomaly.info.score >= 1 && anomaly.info.score < 7 && (
              <div className="feu-tricolore w-7 h-7 bg-orange-400 rounded-full" style={circleStyle}></div>
            )}
            {anomaly.info.score < 1 && (
              <div className="feu-tricolore w-7 h-7 bg-sky-600 rounded-full" style={circleStyle}></div>
            )}
          </div>
        </div>
      ) : (
        <div className="anomaly-detail bg-gray-700 transition-all duration-250 m-2">
          <div
            key={index}
            className="m-2 p-3 relative z-40 group flex justify-between"
            tabIndex={0}
            onClick={() => handleClick(alerts)}
            onMouseEnter={() => handleMouseEnter(alerts)}
            onMouseLeave={() => handleMouseLeave(alerts)}
          >
            <div className="grande-carte-ir-items-clicked">
              {imageMap.reportsubtype[anomaly.info.reportsubtype] ? (
                <img
                  src={imageMap.reportsubtype[anomaly.info.reportsubtype]}
                  alt="test_round"
                  className="icon-popup no-cursor alert-inside-irregularities"
                  style={imageStyle}
                />
              ) : (
                <img
                  src={imageMap.reporttype['Danger']}
                  alt="test2_round"
                  className="icon-popup no-cursor alert-inside-irregularities"
                  style={imageStyle}
                />
              )}
              <div className="anomaly-clicked-title">
              {anomaly.info.reportsubtype}
            </div>
            </div>
            
            <div className="grande-carte-ir-items-clicked">
              Il y a {minutesAgo(anomaly.pubMillis)} 
            </div>
          </div>
          <div className="anomaly-accident-description">
            {/* <div className="anomaly-clicked-text">Score :</div>
            <div className="jauge-container">
              <Jauge
                title="Score"
                className="grande-carte-popup-jauge"
                value={anomaly.info.score}
              />
            </div> */}
          </div>
        </div>
      )}
    </div>
  );

  } else if (anomaly.info.category === 'accident' && anomaly.info.reportsubtype ==='tipi') {
    return (
      <>
        {selectedFeature !== anomaly.uuid ? (
          <div
            key={index}
            className={`anomaly-detail transition-all duration-250 bg-stone-200 hover:bg-gray-700 focus:bg-white m-2 p-3 relative z-40 group flex justify-between`}
            onClick={()=> handleClick(tipi)}
            onMouseEnter={() => handleMouseEnter(tipi)}
      onMouseLeave={() => handleMouseLeave(tipi)}
            tabIndex={0}
          >
            <div className="grande-carte-ir-items group-hover:text-white group-focus:text-black">
            <img
                           src={imageMap.reporttype['Accident']}
                          alt="test_round"
                          className="icon-popup no-cursor"
                          style={imageStyle}
                        />
            </div>
            <div>
              <div className="grande-carte-ir-items2 ir-items-blue-title bold group-hover:text-white group-focus:text-black">
                Accident
              </div>
              <div className="anomaly-time group-hover:text-white">
                {minutesAgo(anomaly.pubMillis)} 
              </div>
            </div>
            <div className="grande-carte-ir-items group-hover:text-white group-focus:text-black">
              <div className="feu-tricolore w-7 h-7 bg-red-500 rounded-full" style={circleStyle}></div>
            </div>
          </div>
        ) : (
          <div className="anomaly-detail bg-gray-700 transition-all duration-250 m-2">
            <div
              key={index}
              className="m-2 p-3 relative z-40 group flex justify-between"
              tabIndex={0}
              onClick={()=> handleClick(tipi)}
              onMouseEnter={() => handleMouseEnter(tipi)}
        onMouseLeave={() => handleMouseLeave(tipi)}
            >
              <div className="grande-carte-ir-items-clicked">
              <img
                           src={imageMap.reporttype['Accident']}
                          alt="test_round"
                          className="icon-popup no-cursor alert-inside-irregularities"
                          style={imageStyle}
                        />
                        <div className="anomaly-clicked-title">Accident</div>
              </div>
              
              <div className="grande-carte-ir-items-clicked">
                Il y a {minutesAgo(anomaly.pubMillis)} 
              </div>
            </div>
            <div className="anomaly-accident-description">
              Source: TIPI <br /> {anomaly.info.road} <br />{" "}
              {Object.keys(anomaly.info.dic_vehicule).length > 0 && (
                <>
                  {Object.keys(anomaly.info.dic_vehicule).map((key) => (
                    <span key={key}>
                      {key}: {anomaly.info.dic_vehicule[key]}
                    </span>
                  ))}
                </>
              )}{" "}
              {/* <div className="jauge-container">
              <Jauge
                title="Score"
                className="grande-carte-popup-jauge"
                value={anomaly.info.score}
              />
            </div> */}
            </div>
            
          </div>
        )}
      </>
    );
  }
};

export default AnomalyGrandeCarte;
