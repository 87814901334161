import React, { useState, useEffect } from 'react';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function Jauge_score_popup({ setFilter, filter }) {
  const [value, setValue] = useState([filter.minscore_popup, filter.maxscore_popup]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeCommitted = (event, newValue) => {
    setFilter(previousState => ({
      ...previousState,
      
      minscore_popup: newValue[0],
      maxscore_popup: newValue[1]
    }));
    console.log(filter, 'newvalue');
  };

  const valuetext = (value) => {
    return `${value}`;
  };

  return (
    <Box sx={{ width: '100%' }}>
     
      <Slider
        getAriaLabel={() => 'Score'}
        value={value}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommitted}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        min={0}
        max={10}
        size="small"
        
      />
    </Box>
  );
}