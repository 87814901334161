import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const Footer = () => {
  return (
    <Box className="footer" >
      <Typography className='footer-text' variant="body2"  component="p" >
        &copy; Made by Hydros in 2024. All Rights Reserved.
      </Typography>
    </Box>
  );
};

export default Footer;