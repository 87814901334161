import React from "react";
import { Typography, Button, Container } from "@mui/material";
import { Link } from "react-router-dom";
import Logo_Hydros from "./img/Logo_Hydros.png"
import Gendarmerie from "./img/Gendarmerie.png"

function Home() {
  return (
    <div className="bg">
      <div className="homepage-top"><img src={Gendarmerie} alt="Your Logo" className="logo" /><img src={Logo_Hydros} alt="Your Logo" className="logo" /></div> 
    <Container maxWidth="sm" className="home-container">
    
      <Typography variant="h3" gutterBottom className="projet-vesper">
        Projet Vesper  
      </Typography> 

      <Typography variant="h4" gutterBottom className="projet-vesper">Sécurité des mobilités </Typography>

      <Typography variant = "h5" className="homepage-description">
      Utilisez les données de Waze pour veiller sur la circulation routière.
      </Typography>
      <br />
      <Button variant="contained" className="button button-home" fullWidth to="/login" component={Link} >
        Login
      </Button>
    </Container>

    
    </div>
    
  );
}

export default Home;