import React, { useState, useEffect } from "react";
import axios from "axios";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import pinceau_img from "../img/pinceau.png";
import icon_close from "../img/icon_close.svg";
import confirm from "../img/confirm.png";
import Swal from "sweetalert2";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Button } from "@mui/material";

const RouteBarree = (props) => {
  const [data, setData] = useState({});
  const [fluxwaze, setFluxwaze] = useState({});
  const [expanded, setExpanded] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null); // New state for tracking editing index
  const [updateData, setUpdateData] = useState({});


  useEffect(() => {
    if (props.department) {
      fetchData();
      fetchFluxwaze();
    }
  }, [props.nClickUpdate,props.department]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACK_URL}/add_data?department=${props.department}`,
        {
          headers: {
            Authorization: "Bearer " + props.token,
          },
        }
      );
      const historic = response.data.incidents;
      historic.forEach((item) => {
        // console.log(item.starttime, "starttime"); // Check each starttime
        // console.log(new Date(item.starttime), "parsed starttime"); // Check each parsed date
      });
      // console.log(response.data);
      historic.sort((a, b) => new Date(b.starttime) - new Date(a.starttime));
      // console.log(historic, "hisotric");
      setData(historic);
      // console.log(data);
    } catch (error) {
      console.error(error);
    }
  };

  const updateWazeFeed = () => {
    fetchData();
    fetchFluxwaze();
  };
  
  const fetchFluxwaze = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACK_URL}/flux_waze?department=${props.department}`,
        {
          headers: {
            Authorization: "Bearer " + props.token,
          },
        }
      );
      const current = response.data.incidents.sort(
        (a, b) => new Date(b.starttime) - new Date(a.starttime)
      );
      setFluxwaze(current);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClick = () => {
    props.setpriority((prevState) => ({
      ...prevState,
      1: !prevState[1],
    }));
  };

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const handleEndtimeChange = (incident_id, newValue) => {
    const currentTime = Date.now();

    setUpdateData({
      id: incident_id,
      endtime: new Date(newValue).getTime(),
      updatetime: currentTime,
      department: props.department,
    });

    // const updatedIncidents = [...fluxwaze.incidents];
    // updatedIncidents[index].endtime = newValue;
    // setData((prevState) => ({
    //   ...prevState,
    //   incidents: updatedIncidents,
    // }));
  };

  const handlePinceauClick = (index) => {
    setEditingIndex(index);
  };

  const handleUpdate = async (index) => {
    // const creationTime = new Date(incident.creationtime).getTime();
    // const starttime = new Date(incident.starttime).getTime();
    // const endtime = new Date(incident.endtime).getTime();

    // verifier que endtime dans json
    // console.log(updateData, "Updatedata");
    // console.log(typeof updateData);
    if ("endtime" in updateData) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACK_URL}/update_flux_waze`,
          updateData,
          {
            headers: {
              Authorization: "Bearer " + props.token,
            },
          }
        );
        // console.log(response);
        Swal.fire({
          title: "La date de fin a été mise à jour.",
          icon: "success",
          showCancelButton: true,
          confirmButtonText: "Retour à la carte",
          cancelButtonText: "Fermer une autre route",
          customClass: {
            confirmButton: "button",
            cancelButton: "button",
            title: "custom-title-class",
          },
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.confirm) {
            window.location.href = "/visu_grande_carte";
          }
          if (result.dismiss === Swal.DismissReason.cancel) {
            updateWazeFeed();
          }
        });
      } catch (error) {
        console.error(error.response);
      }
    }
  };

  return (
    <div className="fluxwaze-api">
      <Accordion className="anomaly " expanded={expanded}>
        <AccordionSummary
          className="panel-header no-cursor"
          aria-controls="panel-content"
          id="panel-header"
        >
          <Typography className="data-title-fluxwaze">
            Routes fermées
          </Typography>

          <div className="fluxwaze-api-accordition-container">
            <div className="routes-fermees-boutons">
              <div
                className={
                  props.priority[1]
                    ? "custom-style ano-filtre-item no-cursor"
                    : "custom-style cursor"
                }
                onClick={() => handleClick()}
              >
                En cours
              </div>

              <div
                className={
                  !props.priority[1]
                    ? "custom-style ano-filtre-item no-cursor"
                    : "custom-style cursor "
                }
                onClick={() => handleClick()}
              >
                Historique
              </div>
            </div>
            <div>
              <Button
                type="submit"
                variant="contained"
                className="button update-fluxwaze"
                onClick={() => props.setnClickUpdate(props.nClickUpdate + 1)}
              >
                Mettre à jour
              </Button>
            </div>
          </div>

          <div className="fluxwaze-detail transition-all duration-250 focus:bg-white m-2 p-3 relative z-40 group grid grid-cols-3">
            <div className="column-title bold-title" style={{ flexGrow: 1 }}>
              Début
            </div>
            <div className="column-title bold-title" style={{ flexGrow: 1 }}>
              Fin
            </div>
            <div className="column-title bold-title column-street" style={{ flexGrow: 1 }}>
              RUE
            </div>
            <div
              className="column-title bold-title"
              style={{ flexGrow: 1 }}
            ></div>
          </div>

          <div className="fluxwaze-container">
            {!props.priority[1] &&
              data &&
              data.length > 0 &&
              data.map((incident, index) => (
                <div
                  key={index}
                  className={`fluxwaze-detail transition-all bg-gray-100 duration-250  focus:bg-white m-2 p-3 relative z-40 group grid grid-cols-3`}
                >
                  <div className="column-title">
                    {new Date(incident.starttime).toLocaleString()}{" "}
                  </div>
                  <div className="column-title ">
                    <div>{new Date(incident.endtime).toLocaleString()} </div>
                  </div>
                  <div className="column-title column-street">
                    <div>
                    <p
                      className={` ${
                        /^D[0-9]/.test(incident.street)
                          ? "fluxwaze-departementale"
                          : /^N[0-9]/.test(incident.street)
                          ? "fluxwaze-nationale"
                          : /^A[0-9]/.test(incident.street)
                          ? "fluxwaze-autoroute"
                          : "fluxwaze-street"
                      }`}
                    >
                      {incident.street}
                    </p>
                    </div>
                    <div class="flux-waze-tricolore bg-red-500  rounded-full"></div>
                  </div>
                </div>
              ))}

            {props.priority[1] &&
              fluxwaze &&
              fluxwaze.length > 0 &&
              fluxwaze.map((incident, index) => (
                <div
                  key={index}
                  className={`fluxwaze-detail transition-all duration-250 bg-gray-100  focus:bg-white m-2 p-3 relative z-40 group grid grid-cols-3`}
                >
                  <div className="column-title">
                    {new Date(incident.starttime).toLocaleString()}{" "}
                  </div>
                  <div className="column-title ">
                    {editingIndex !== index ? (
                      <div className="endttime">
                        {new Date(incident.endtime).toLocaleString()}{" "}
                        <img
                          src={pinceau_img}
                          alt="Pinceau_img"
                          className="pinceau_img cursor"
                          onClick={() => handlePinceauClick(index)}
                        />
                      </div>
                    ) : (
                      <div className="endttime">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            id="endttime"
                            label="Date de fin"
                            value={dayjs(incident.endtime)}
                            format="YYYY-MM-DD HH:mm" // Specify the format to include both date and time
                            onChange={(newValue) =>
                              handleEndtimeChange(incident.id, newValue)
                            }
                          />
                        </LocalizationProvider>
                        <div className="flux-waze-img-container">
                          <img
                            src={confirm}
                            alt="confirm"
                            className="icon-update icon-confirm cursor green-filter"
                            id="confirm"
                            onClick={() => {
                              setEditingIndex(null);
                              handleUpdate(index);
                            }}
                          />
                          <img
                            src={icon_close}
                            alt="close"
                            id="icon-close"
                            className="icon-update cursor red-filter"
                            onClick={() => {
                              setEditingIndex(null);
                              setUpdateData({});
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="column-title column-street">
                    <div>
                      <p
                        className={` ${
                          /^D[0-9]/.test(incident.street)
                            ? "fluxwaze-departementale"
                            : /^N[0-9]/.test(incident.street)
                            ? "fluxwaze-nationale"
                            : /^A[0-9]/.test(incident.street)
                            ? "fluxwaze-autoroute"
                            : "fluxwaze-street"
                        }`}
                      >
                        {incident.street}
                      </p>
                    </div>
                    <div class="flux-waze-tricolore bg-green-500  rounded-full"></div>
                  </div>
                </div>
              ))}
          </div>
        </AccordionSummary>

        <div className="less" onClick={handleChange}>
          Voir moins
        </div>
      </Accordion>
    </div>
  );
};

export default RouteBarree;
